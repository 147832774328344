import React, { useState, useEffect } from 'react'
import '../../App.css'
import './index.scss'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, FormGroup, Input, Button } from 'reactstrap'
import * as yup from 'yup'
import { ProjectDetails } from '../../utils/types'
import { createProject } from '../../api'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { FormErrorMessage } from '../../component/FormErrorMessage'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  projectName: yup
    .string()
    .required('Project Name is required')
    .label('Project Name'),
  projectValueInCents: yup
    .number()
    .required('Project Value is required')
    .label('Project Value'),
  projectCadaster: yup
    .string()
    .required('Project Cadaster is required')
    .label('Project Cadaster'),
  projectDescription: yup
    .string()
    .required('Project Description is required')
    .label('Project Description'),
  projectAddress: yup.object().shape({
    street: yup.string().required('Street is required').label('Street'),
    city: yup.string().required('City is required').label('City'),
    postal: yup.string().required('Postal is required').label('Postal'),
    state: yup.string().required('State is required').label('State'),
    country: yup.string().required('Country is required').label('Country'),
  }),
})

const AddNewLand: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isValid },
  } = useForm<ProjectDetails>({
    resolver: yupResolver(schema),
  })

  const [isLoading, setIsLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')

  const [errorMessage, setErrorMessage] = useState<string>('')

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  // 	const { name, value } = event.target;
  // 	const parsedValue = name === "projectValueInCents" ? parseFloat(value) * 100 : value;

  // 	setProjectDetails((prevProject) => ({
  // 		...prevProject!,
  // 		[name]: parsedValue,
  // 	}));
  // };

  // const handleProjectAddressInputChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { name, value } = event.target;
  //   const [outerField, innerField] = name.split(".");
  //   setProjectDetails((prevProject) => ({
  //     ...prevProject!,
  //     projectAddress: {
  //       ...prevProject!.projectAddress,
  //       [innerField]: value,
  //     },
  //   }));
  // };

  const onSubmit = async (projectDetails: ProjectDetails) => {
    if (!projectDetails) return
    setIsLoading(true)
    setErrorMessage('')
    createProject(projectDetails)
      .then(async ({ data: { message } }) => {
        setSaveMessage(message)
        setTimeout(() => {
          setSaveMessage('')
          setIsLoading(false)
        }, 500)
      })
      .catch((err) => {
        setIsLoading(false)
        setErrorMessage(err.response.data.message)
        toast.error(err.response.data.message, {
          position: 'bottom-center',
        })

        console.log('error', err)
      })
  }

  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                <Link to="/">Dashboard</Link>
              </li>
              <li
                className="breadcrumb-item active"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                Add New Land
              </li>
            </ol>
            <Form
              // className={`row ${!isValid ? "needs-validation" : ""}  ${
              //   isSubmitted ? "was-validated" : ""
              // }`}
              className={`row `}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="col-lg-12">
                <div className="box_general padding_bottom add-land-form">
                  <div className="header_box version_2">
                    <h2>
                      <i className="fa fa-plus "></i>Enter Land Details
                    </h2>
                  </div>
                  {saveMessage && (
                    <div className="save-message">{saveMessage}</div>
                  )}

                  <div className="row">
                    <div className="col-md-4">
                      <FormGroup>
                        <label>Land Project Name</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectName')}
                        />
                        <FormErrorMessage>
                          {errors.projectName?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>

                    <div className="col-md-4">
                      <FormGroup>
                        <label>Land Value In AUD</label>
                        <input
                          type="number"
                          className="form-control"
                          {...register('projectValueInCents', {
                            setValueAs: (value) => {
                              return value !== '' ? parseFloat(value) * 100 : 0
                            },
                          })}
                        />
                        <FormErrorMessage>
                          {errors.projectValueInCents?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>

                    <div className="col-md-4">
                      <FormGroup>
                        <label>Cadaster Number</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            {...register('projectCadaster')}
                          />
                          <FormErrorMessage>
                            {errors.projectCadaster?.message}
                          </FormErrorMessage>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <label>Description</label>
                        <textarea
                          className="form-control"
                          {...register('projectDescription')}
                        ></textarea>
                        <FormErrorMessage>
                          {errors.projectDescription?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <label>Street Addres</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectAddress.street')}
                        />
                        <FormErrorMessage>
                          {errors.projectAddress?.street?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <label>City or Locality</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectAddress.city')}
                        />
                        <FormErrorMessage>
                          {errors.projectAddress?.city?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectAddress.postal')}
                        />
                        <FormErrorMessage>
                          {errors.projectAddress?.postal?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>

                    <div className="col-md-4">
                      <FormGroup>
                        <label>State</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectAddress.state')}
                        />
                        <FormErrorMessage>
                          {errors.projectAddress?.state?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <label>Country</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectAddress.country')}
                        />
                        <FormErrorMessage>
                          {errors.projectAddress?.country?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <h4 className="textCenter">{errorMessage}</h4>

                <QButton type="submit" round size="lg" loading={isLoading}>
                  Submit
                </QButton>
              </div>
            </Form>
          </div>
        </div>
      </body>
    </>
  )
}

export default AddNewLand
