import React, { useCallback, useState } from 'react'
import Header from '../Sidbar'
import Footer from '../Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import SingleDatePickerComponent from '../SingleDatePickerComponent'
import { FormGroup, Input, Label } from 'reactstrap'
import moment from 'moment'
import QButton from '../DesignSystem/Core/Common/QButton/QButton'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as yup from 'yup'
import {
  LandProjectTypes,
  ProjectColorSatus,
  ProjectObjectInteface,
  SingleQuestionInterface,
  VoteTopicObjectInteface,
} from '../../utils/types'
import { validateProjectData } from '../../utils/utilities'
import { toast } from 'react-toastify'
import {
  projectStatusObject,
  testAxios,
  voteQuestionSingle,
} from '../../utils/constants'
import {
  CustomReactQuillV3,
  IsProjectCompleteOptionsComponent,
  ProjectStatusOptionList,
} from '../misc/smallComponents'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormErrorMessage } from '../FormErrorMessage'
import { addImage } from '../../api'

export interface LandProjectFormValues {
  projectName: string
  type: 'Agricultural' | 'Real Estate'
  location: string
  projectDetails: string,
  projectSummary: string,
  keyDetailsAndStatistics: string,

  landProjectPrimaryImage: string
}

export const projectSchema = yup.object().shape({
  projectName: yup.string().required('Project name is required'),
  type: yup
    .mixed<'Agricultural' | 'Real Estate'>()
    .oneOf(
      ['Agricultural', 'Real Estate'],
      'Type must be either Agricultural or Real_Estate',
    )
    .default('Agricultural')
    .required('Type is required'),
  location: yup.string().required('Location is required'),
  projectDetails: yup.string().required('Project details are required'),
  keyDetailsAndStatistics: yup.string().required('Project key Details And Statistics are required'),
  projectSummary: yup.string().required('Project summary are required'),
  landProjectPrimaryImage: yup
    .string()
    .required('Image  is required')
    .url('Image URL must be a valid URL'),
})

const LandProjectAdd: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const AWS_FILE_VIEW_URI = process.env.REACT_APP_AWS_FILE_VIEW_URI
  const toggle_doc_modal = () => setdoc_Modal(!doc_modal)
  const [doc_modal, setdoc_Modal] = useState(false)
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LandProjectFormValues>({
    resolver: yupResolver(projectSchema),
  })

  console.log('control:', control)

  console.log('errors:', errors)
  const onSubmit: SubmitHandler<LandProjectFormValues> = async (data) => {
    const url = `${process.env.REACT_APP_SERVER_URI}/land-project`

    try {
      setLoading(true)

      const response = await testAxios.post(url, {
        ...data,
      })
      toast.success(response.data.message, {
        position: 'bottom-center',
      })
      setLoading(false)
      navigate('/viewLandProjects')
    } catch (error: any) {
      console.log('error:', error)
      setLoading(false)
      toast.error(error.response.data.message, { position: 'bottom-center' })
    }
  }
  const handleImageUpload = useCallback(() => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files?.[0]
      if (file) {
        const formData = new FormData()
        formData.append('image', file)

        try {
          const response = await addImage(formData)
          //@ts-ignore
          const imageUrl = response.data.fileUrl
          setValue('landProjectPrimaryImage', imageUrl)
          trigger("landProjectPrimaryImage")
        } catch (error) {
          console.error('Error uploading image:', error)
          alert('Error uploading image')
        }
      }
    }
  }, [])

  return (
    <div className="row mt-4 pb-4">
      <div className="col-md-12">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <Controller
              name="landProjectPrimaryImage"
              control={control}
              render={({ field }) => (
                <div className="d-flex">
                  <QButton
                    color="success"
                    onClick={handleImageUpload}
                    className="mr-4"
                  >
                    Add Image
                  </QButton>
                  {field.value && (
                    <QButton
                      color="success"
                      onClick={() => {
                        toggle_doc_modal()
                        console.log('getValues():', getValues())
                      }}
                    >
                      View Image
                    </QButton>
                  )}
                </div>
              )}
            />

            {errors.landProjectPrimaryImage && (
              <FormErrorMessage>
                {errors.landProjectPrimaryImage.message}
              </FormErrorMessage>
            )}
          </div>

          <div className="form-group">
            <Label>
              <b>Enter Project Name</b>
            </Label>
            <Controller
              name="projectName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Project Name"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.projectName && (
              <FormErrorMessage>{errors.projectName.message}</FormErrorMessage>
            )}
          </div>
          <div className="form-group">
            <Label>
              <b>Enter Location</b>
            </Label>
            <Controller
              name="location"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Project Location"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.location && (
              <FormErrorMessage>{errors.location.message}</FormErrorMessage>
            )}
          </div>
          <div className="form-group">
            <Label>
              <b>Type</b>
            </Label>
            <Controller
              name="type"
              control={control}
              defaultValue="Agricultural"
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="select"
                  placeholder="Enter Type"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <option value={LandProjectTypes.Agricultural}>
                    {LandProjectTypes.Agricultural}
                  </option>
                  <option value={LandProjectTypes.Real_Estate}>
                    {LandProjectTypes.Real_Estate}
                  </option>
                </Input>
              )}
            />
            {errors.type && (
              <FormErrorMessage>{errors.type.message}</FormErrorMessage>
            )}
          </div>
          <div className="form-group">
            <Label>
              <b> Project key Details And Statistics</b>
            </Label>
            <FormGroup>
              <Controller
                name="keyDetailsAndStatistics"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CustomReactQuillV3
                    {...field}
                    className={'static-text-content-page'}
                    theme="snow"
                    value={field.value}
                    onChange={(v: string) => field.onChange(v)}
                    style={{
                      height: 200,
                      marginBottom: 60,
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.keyDetailsAndStatistics?.message}
              </FormErrorMessage>
            </FormGroup>


          </div>
          <div className="form-group">
            <Label>
              <b>Enter Land Project Summary</b>
            </Label>
            <FormGroup>
              <Controller
                name="projectSummary"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CustomReactQuillV3
                    {...field}
                    className={'static-text-content-page'}
                    theme="snow"
                    value={field.value}
                    onChange={(v: string) => field.onChange(v)}
                    style={{
                      height: 200,
                      marginBottom: 60,
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.projectSummary?.message}
              </FormErrorMessage>
            </FormGroup>


          </div>
          <div className="form-group">
            <Label>
              <b>Enter Land Project Details</b>
            </Label>
            <FormGroup>
              <Controller
                name="projectDetails"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CustomReactQuillV3
                    {...field}
                    className={'static-text-content-page'}
                    theme="snow"
                    value={field.value}
                    onChange={(v: string) => field.onChange(v)}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.projectDetails?.message}
              </FormErrorMessage>
            </FormGroup>


          </div>

          <div className="text-right ml-auto" style={{ maxWidth: '250px' }}>
            <QButton
              round
              onClick={() => navigate('/viewLandProjects')}
              className="mr-3"
            >
              <b>Go Back</b>
            </QButton>
            <QButton round loading={loading} type="submit" >
              <b>Submit</b>
            </QButton>
          </div>
        </form>
      </div>
      <Modal
        className="modal-lg"
        isOpen={doc_modal}
        toggle={toggle_doc_modal}
        unmountOnClose={true}
        centered
      >
        <ModalHeader toggle={toggle_doc_modal}></ModalHeader>
        <ModalBody>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive"
              // src={`${AWS_FILE_VIEW_URI}/${updatedNews?.img}`}
              src={getValues().landProjectPrimaryImage}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <QButton color="primary" onClick={toggle_doc_modal}>
            Close
          </QButton>{' '}
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default LandProjectAdd
