import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import axios from 'axios'
import EditQuestions from '../goverancePage/editQuestion'
import moment from 'moment'
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Button,
  ModalFooter,
} from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'
import { getGovernanceQuestions } from '../../api'
import { testAxios } from '../../utils/constants'
export default function ViewQuestion() {
  const [deleteModal, setdeleteModal] = useState(false)
  const [topicEditModal, settopicEditModal] = useState(false)
  const [deleteLoading, setdeleteLoading] = useState(false)
  const toogleDeleteModal = () => {
    setdeleteModal(!deleteModal)
  }
  const toogleEditModal = () => {
    setdeleteModal(!deleteModal)
  }
  const [msg, setMsg] = useState<any>()
  const [data, setData] = useState<any>()
  const [questionId, setQuestionId] = useState<any>()
  const [id, setId] = useState<any>()
  const [activeID, setactiveID] = useState<string>('')
  const [showChildComponent, setShowChildComponent] = useState(false)

  const navigate = useNavigate()

  const DeleteQuestion = async () => {
    const url = process.env.REACT_APP_SERVER_URI + `/goverance/${questionId}`
    await axios
      .delete(url)
      .then((response) => {
        setMsg(response.data.message)
        setMsg('')
        getAllVoteTopics()
      })
      .catch((error) => {
        toast.error(error.response.data.message, { position: 'bottom-center' })
      })
  }
  const DeleteTopic = async () => {
    try {
      setdeleteLoading(true)
      const url = process.env.REACT_APP_SERVER_URI + `/goverance/${activeID}`
      await testAxios
        .delete(url)
        .then((response) => {
          setdeleteLoading(false)
          toogleDeleteModal()
          setMsg(response.data.message)
          toast.success(response.data.message, { position: 'bottom-center' })
          setMsg('')
          getAllVoteTopics()
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: 'bottom-center',
          })
        })
    } catch (e) {
      console.log('e:', e)
    }
  }
  const EditQuestion = async (id: any) => {
    setId(id)
    setShowChildComponent(true)
    navigate('/editQuestion', { state: { id } })
  }
  const getAllVoteTopics = async () => {
    getGovernanceQuestions()
      .then((response) => {
        setData(response.data)
        setMsg(response.data)
      })
      .catch((error) => {
        toast.error(error.response.data.message, { position: 'bottom-center' })
      })
  }
  useEffect(() => {
    getAllVoteTopics()
  }, [])

  return (
    <>
      {/* <div>{showChildComponent && <EditQuestions data={id} />}</div> */}
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View Goverance</li>
              <li>
                <QButton
                  onClick={() => navigate('/addQuestion')}
                  round
                  size="sm"
                  className="ml-3"
                >
                  Add Question
                </QButton>
              </li>
            </ol>
            <table
              className="table table-bordered"
              id="dataTable"
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Vote Topic</th>
                  <th> Questions</th>
                  {/* <th>Options</th> */}
                  {/* <th>Select Answer</th> */}
                  <th>Start Date</th>
                  <th>End Date</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) &&
                  data.map((topic: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <b>{index + 1}.</b>
                      </td>
                      <td>
                        <b>{topic.voteTopicTitle}.</b>
                      </td>
                      <td>
                        {topic.voteQuestions.map(
                          (questionItem: any, questionIndex: number) => (
                            <div className="question-single-view">
                              <h6>{questionIndex + 1}.</h6>{' '}
                              {questionItem?.questionTitle} <br />
                              {Object.keys(questionItem?.answerOptions).map(
                                (k: string) => {
                                  return (
                                    <span
                                      className={
                                        questionItem?.answerKey === k
                                          ? 'text-primary font-weight-bold'
                                          : ''
                                      }
                                    >
                                      <b>{k}.</b>{' '}
                                      {questionItem?.answerOptions[k]} <br />
                                    </span>
                                  )
                                },
                              )}
                            </div>
                          ),
                        )}
                      </td>

                      {/* <td>
                        {item.selectedAnswers.map(
                          (questionItem: any, questionIndex: number) => {
                            const data = questionItem.split(',')
                            return (
                              <React.Fragment key={questionIndex}>
                                <b>{questionIndex + 1}.</b> {data}
                                <br />
                              </React.Fragment>
                            )
                          },
                        )}
                      </td> */}
                      <td>{moment(topic.startDate).format('DD/MM/YYYY')}</td>
                      <td>{moment(topic.endDate).format('DD/MM/YYYY')}</td>
                      <td>
                        <QButton
                          size="sm"
                          onClick={() => {
                            // navigate(`/editQuestion?id=${topic._id}`)
                            navigate('/editQuestion/' + topic._id)
                            // navigate(`/feemanagement`)
                          }}
                          round
                          className="mr-2"
                        >
                          Edit
                        </QButton>
                        <QButton
                          size="sm"
                          onClick={() => {
                            setactiveID(topic._id)
                            toogleDeleteModal()
                          }}
                          round
                        >
                          Delete
                        </QButton>
                        {/* <EditIcon /> */}
                      </td>
                      {/* <td
                    
                      >
                        <DeleteOutlineIcon />
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
            <Modal
              isOpen={deleteModal}
              toggle={toogleDeleteModal}
              className="modal-dialog-centered "
              scrollable
              // size="lg"
              unmountOnClose={true}
            >
              <ModalHeader>Confirm.</ModalHeader>
              <ModalBody>Do you want to delete this Vote Topic ?</ModalBody>

              <ModalFooter>
                <QButton round className="mr-3">
                  Close
                </QButton>
                <QButton round loading={deleteLoading} onClick={DeleteTopic}>
                  Delete
                </QButton>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </body>
    </>
  )
}
