import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import './index.scss'
import { ONE_AUD_TO_CENTS } from '../../utils/utilities'
import {
  BuyCurrencyEnum,
  ExternalCardCryptoFeeFormData,
} from '../../utils/types'
import {
  getCurrencyUnitsApi,
  getExternalfee,
  updateCurrencyUnitFeesApi,
  updateExternalFeesApi,
} from '../../api'
import UpdateExternalCardCryptoFees from '../../component/FeeManagement/UpdateExternalCardCryptoFees'
import { toast } from 'react-toastify'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { FormGroup } from 'reactstrap'
const FeeManagement = () => {
  const [fees, setFees] = useState<any>([])
  const [formData, setFormData] = useState({
    type: '',
    buyOrderTransactionFeeInCents: 0,
    sellOrderTransactionFeeInUnits: 0,
    secondaryMarketTransactionPercentage: 0,
  })

  const [externalFees, setExternalFees] =
    useState<ExternalCardCryptoFeeFormData>({
      cardFeePercentage: 0,
      cardFeeCents: 0,
      cryptoFeePercentage: 0,
      cryptoFeeCents: 0,
      extraFees: 0,
    })

  const [updatedExternalFees, setUpdatedExternalFees] =
    useState<ExternalCardCryptoFeeFormData>({
      cardFeePercentage: 0,
      cardFeeCents: 0,
      cryptoFeePercentage: 0,
      cryptoFeeCents: 0,
      extraFees: 0,
    })

  const getExternalFeeDetails = async () => {
    try {
      const fetchedExternalFees = (await getExternalfee()).data?.data

      const {
        cardFeePercentage,
        cardFeeCents,
        cryptoFeePercentage,
        cryptoFeeCents,
        extraFees,
      } = fetchedExternalFees

      const feesData = {
        cardFeePercentage,
        cardFeeCents,
        cryptoFeePercentage,
        cryptoFeeCents,
        extraFees,
      }

      setExternalFees(feesData)
      setUpdatedExternalFees(feesData)
    } catch (error: any) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  const getCurrencyUnits = async () => {
    getCurrencyUnitsApi()
      .then((res) => {
        setFees(res.data)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }

  const handleExternalFeeSubmit = async () => {
    try {
      console.log('updatedExternalFees', updatedExternalFees)
      const { data: updateExternalFees, message: successMessage } = (
        await updateExternalFeesApi(updatedExternalFees)
      ).data

      toast.success(successMessage)
      // alert('External Fees updated')
      setExternalFees((prevFees) => ({
        ...prevFees,
        ...updateExternalFees,
      }))
    } catch (error: any) {
      console.log('error', error)
      if (error.code === 'ERR_BAD_REQUEST') {
        // alert(`${error.response.data.message}`)
        toast.error(error.response.data.message)
      } else if (error.code === 'ERR_NETWORK') {
        // alert(`${error.message}`)
        toast.error('Server down')
      }
    }
  }

  const handleSubmit = async () => {
    if (formData.type === '') {
      return
    }
    try {
      const { data: updateFees, message: successMessage } = (
        await updateCurrencyUnitFeesApi(formData)
      ).data
      console.log('update fees', updateFees)
      toast.success(successMessage)

      // alert('Internal Fees updated')

      const updatedCurrencyUnits = fees.map((unit: any) =>
        unit.type === updateFees.type ? updateFees : unit,
      )

      setFees(updatedCurrencyUnits)
    } catch (error: any) {
      console.log('error', error)
      toast.error(error.response.data.message)
    }
  }

  const handleExternalFeeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target
    setUpdatedExternalFees((prevFees) => ({
      ...prevFees,
      [name]: value, // Update the corresponding fee property
    }))
  }
  console.log('update externalfees', updatedExternalFees, externalFees)
  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    if (name === 'type') {
      const defaultFee = fees.find((fee: any) => fee.type === value)
      if (value === 'LAND') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          buyOrderTransactionFeeInCents:
            defaultFee?.buyOrderTransactionFeeInCents || 0,
          sellOrderTransactionFeeInUnits:
            defaultFee?.sellOrderTransactionFeeInUnits || 0,
          secondaryMarketTransactionPercentage:
            defaultFee?.secondaryMarketTransactionPercentage || 10,
        }))
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          buyOrderTransactionFeeInCents:
            defaultFee?.buyOrderTransactionFeeInCents || 0,
          sellOrderTransactionFeeInUnits:
            defaultFee?.sellOrderTransactionFeeInUnits || 0,
          secondaryMarketTransactionPercentage: 0,
        }))
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: parseFloat(value) }))
    }
  }
  useEffect(() => {
    getCurrencyUnits()
    getExternalFeeDetails()
  }, [])
  return (
    <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
      <div className="content-wrapper ">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li
              className="breadcrumb-item"
              style={{
                paddingTop: '2rem',
                paddingBottom: '0.6rem',
              }}
            >
              <Link to="/">Dashboard</Link>
            </li>
            <li
              className="breadcrumb-item active"
              style={{
                paddingTop: '2rem',
                paddingBottom: '0.6rem',
              }}
            >
              Fees Management
            </li>
          </ol>
          <div className="row">
            <div className="col-lg-12">
              <div className="box_general padding_bottom add-land-form">
                <div className="">
                  <h3>Fees Management</h3>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-2">
                    {/* <p>Current Fees</p> */}
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <div>For {BuyCurrencyEnum.LAND} Units:</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mt-1">
                      <div>For {BuyCurrencyEnum.eAUD} Units:</div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mt-1">
                      <div>Card and Crypto External Fees: </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <p>
                        For BUY Order, Current Transaction Fee in AUD:
                        <b>
                          {' '}
                          {fees.find((fee: any) => fee.type === 'LAND')
                            ?.buyOrderTransactionFeeInCents / ONE_AUD_TO_CENTS}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mt-1">
                      <p>
                        For BUY Order, Current Transaction Fee in AUD:
                        <b>
                          {' '}
                          {fees.find((fee: any) => fee.type === 'eAUD')
                            ?.buyOrderTransactionFeeInCents / ONE_AUD_TO_CENTS}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mt-1">
                      <p>
                        Cards Fee Percentage:
                        <b> {externalFees.cardFeePercentage}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <p>
                        For SELL Order, Current Transaction Fee in Unit:
                        <b>
                          {' '}
                          {
                            fees.find(
                              (fee: any) => fee.type === BuyCurrencyEnum.LAND,
                            )?.sellOrderTransactionFeeInUnits
                          }
                        </b>
                      </p>
                      <p>
                        Secondary Market Transaction Percentage:
                        <b>
                          {' '}
                          {
                            fees.find(
                              (fee: any) => fee.type === BuyCurrencyEnum.LAND,
                            )?.secondaryMarketTransactionPercentage
                          }
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <p>
                        For SELL Order, Current Transaction Fee in Unit:
                        <b>
                          {' '}
                          {
                            fees.find(
                              (fee: any) => fee.type === BuyCurrencyEnum.eAUD,
                            )?.sellOrderTransactionFeeInUnits
                          }
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mt-1">
                      <p>
                        Card Fixed Fee in Cents:
                        <b> {externalFees.cardFeeCents}</b>
                      </p>
                      <p>
                        Crypto Fee Percentage:
                        <b> {externalFees.cryptoFeePercentage}</b>
                      </p>
                      <p>
                        Crypto Fixed Fee in Cents::
                        <b> {externalFees.cryptoFeeCents}</b>
                      </p>
                      <p>
                        Extra Fees:
                        <b> {externalFees.extraFees}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <h3>Update Internal Fees</h3>
                    </div>
                    <div className="mt-4">
                      <div>
                        <div className="row">
                          <div className='d-flex align-items-center'>
                            <label className="mr-2">
                              <b>Currency Type</b>
                            </label>
                          </div>
                          <div className="">
                            <select
                              className="form-control"
                              name="type"
                              value={formData.type}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Currency Unit</option>
                              <option value={BuyCurrencyEnum.LAND}>
                                {BuyCurrencyEnum.LAND}
                              </option>
                              <option value={BuyCurrencyEnum.eAUD}>
                                {BuyCurrencyEnum.eAUD}
                              </option>
                            </select>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                        <div className='d-flex align-items-center'>
                            <label className="mr-2">
                              <b>Buy Transaction Fee in Cents</b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="number"
                              className="form-control "
                              placeholder=""
                              min={0}
                              name="buyOrderTransactionFeeInCents"
                              value={formData.buyOrderTransactionFeeInCents}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                        <div className='d-flex align-items-center'>
                            <label className="mr-2">
                              <b>
                                Sell Transaction Fee in{' '}
                                {formData.type + ' Units'}
                              </b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              className="form-control"
                              type="number"
                              min={0}
                              name="sellOrderTransactionFeeInUnits"
                              value={formData.sellOrderTransactionFeeInUnits}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <br />
                        {(formData.type === 'LAND' || formData.type === '') && (
                          <div className="row">
                            <div className='d-flex align-items-center'>
                              <label className=" mr-2">
                                <b>Secondary Market Transaction Percentage</b>
                              </label>
                            </div>
                            <div className="">
                              <input
                                className="form-control"
                                type="number"
                                min={0}
                                max={100}
                                name="secondaryMarketTransactionPercentage"
                                value={
                                  formData.secondaryMarketTransactionPercentage
                                }
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        )}
                        <br />
                        <div className="">
                          <button
                            className="btn_1 rounded  color btnCenter"
                            disabled={formData.type === ''}
                            onClick={handleSubmit}
                          >
                            Update Fees
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <h3>Update External Card and Crypto Fees</h3>
                    </div>
                    <div className="mt-4">
                      <div>
                        <div className="row">
                        <div className='d-flex align-items-center'>
                            <label className="mr-2">
                              <b>Cards Fee Percentage</b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="number"
                              className="form-control "
                              placeholder=""
                              min={0}
                              max={100}
                              name="cardFeePercentage"
                              value={updatedExternalFees.cardFeePercentage}
                              onChange={handleExternalFeeInputChange}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                        <div className='d-flex align-items-center'>
                            <label className="mr-2">
                              <b>Card Fixed Fee in Cents</b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              className="form-control"
                              type="number"
                              min={0}
                              name="cardFeeCents"
                              value={updatedExternalFees.cardFeeCents}
                              onChange={handleExternalFeeInputChange}
                            />
                          </div>
                        </div>

                        <br />
                        <div className="row mb-3">
                        <div className='d-flex align-items-center'>
                            <label className="mr-2">
                              <b>Crypto Fee Percentage</b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="number"
                              className="form-control "
                              placeholder=""
                              min={0}
                              name="cryptoFeePercentage"
                              value={updatedExternalFees.cryptoFeePercentage}
                              onChange={handleExternalFeeInputChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                        <div className='d-flex align-items-center'>
                            <label className=" mr-2">
                              <b>Crypto Fee Cents</b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="number"
                              className="form-control "
                              placeholder=""
                              min={0}
                              name="cryptoFeeCents"
                              value={updatedExternalFees.cryptoFeeCents}
                              onChange={handleExternalFeeInputChange}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                        <div className='d-flex align-items-center'>
                            <label className="mr-2">
                              <b>Extra Fee </b>
                            </label>
                          </div>
                          <div className="">
                            <input
                              className="form-control"
                              type="number"
                              min={0}
                              name="extraFees"
                              value={updatedExternalFees.extraFees}
                              onChange={handleExternalFeeInputChange}
                            />
                          </div>
                        </div>

                        <br />
                        <div className="">
                          <button
                            className="btn_1 rounded  color btnCenter"
                            onClick={handleExternalFeeSubmit}
                          >
                            Update Fees
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          {/* <h4 style={{ color: "red" }}>{msg}</h4> */}
        </div>
      </div>
    </body>
  )
}
export default FeeManagement
