import { isEmpty } from "lodash";
import { ReactElement } from "react";

interface ErrorProps {
  children?: ReactElement | string;
}

export const FormErrorMessage = ({ children }: ErrorProps): JSX.Element => {
  return isEmpty(children) ? (
    <></>
  ) : (
    <div className="form-error-message">
      <b>*{children}</b>
    </div>
  );
};
