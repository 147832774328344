import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { debounce, getDate } from '../../utils/utilities'
import { getVerificationUsers, putDisapproveUser } from '../../api'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from 'reactstrap'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { User } from '../../utils/types'
import { HasToolTipLabel } from '../../component/misc/smallComponents'
export default function ViewApproved() {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const [users, setUsers] = useState<User[]>([])
  const [id, setId] = useState<any>([])
  const [searchType, setSearchType] = useState('name') // Default search type is name
  const [searchTerm, setSearchTerm] = useState('')
  const [disApprovalReason, setDisApprovalReason] = useState('')
  const [saveMessage, setSaveMessage] = useState('')
  const [isDisApproveLoading, setIsDisApproveLoading] = useState(false)
  const [isExportDownloadLoading, setIsExportDownloadLoading] = useState(false)

  const getVerifiedUsers = async () => {
    getVerificationUsers(searchType, searchTerm)
      .then((res) => {
        const users = res.data.users
        // @ts-ignore
        setUsers(users)
      })
      .catch((err) => {
        console.log('error=============', err)
      })
  }

  // Debounced version of getProjectByLandName for 500 mili seconds
  const debouncedGetAllVerifiedUsers = debounce(getVerifiedUsers, 500)

  useEffect(() => {
    debouncedGetAllVerifiedUsers()
  }, [])

  const handleExportUsers = async () => {
    setIsExportDownloadLoading(true)
    try {
      const response = await getVerificationUsers(searchType, searchTerm, true)

      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'users.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setIsExportDownloadLoading(false)
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setIsExportDownloadLoading(false)
    }
  }

  const handleDisApproveUser = async () => {
    setIsDisApproveLoading(true)
    await putDisapproveUser(id, disApprovalReason)
      .then((res) => {
        setSaveMessage(res.data.message)
        setTimeout(() => {
          setSaveMessage('')
          getVerifiedUsers()
          setId(null)
          toggle()
        }, 500)
        setIsDisApproveLoading(false)
      })
      .catch((error: any) => {
        toast.error(error.response.data.message)
        setIsDisApproveLoading(false)
      })
  }

  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View APPROVED USER</li>
            </ol>

            <div className="card mb-3">
              {/* <div className="card-header">
                                <i className="fa fa-list-ul mr-2"></i> List of All Transactions
                            </div> */}
              <div className="card-body">
                <div className="table-responsive">
                  <div>
                    {/* <select className="mr-sm-1 mb-sm-2" value={searchType} onChange={(e) => setSearchType(e.target.value)}>
											<option value="name">Search User by Name</option>
											<option value="email">Search User by Email</option>
										</select>
										<input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
                    <FormGroup className="search-by-wrap">
                      <select
                        className="form-control"
                        value={searchType}
                        onChange={(e) => setSearchType(e.target.value)}
                      >
                        <option value="name">Search User by Name</option>
                        <option value="email">Search User by Email</option>
                      </select>

                      <SearchInput
                        onClick={getVerifiedUsers}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        ButtonText="Search"
                        placeholder={'Search'}
                        // loading={userLoading}
                      />
                    </FormGroup>
                    <QButton
                      loading={isExportDownloadLoading}
                      onClick={handleExportUsers}
                      round
                      size="sm"
                    >
                      Download users
                    </QButton>
                  </div>
                  <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Date Joined</th>
                        <th>Account Number</th>
                        <th>Approved By</th>
                        <th>Note(Optional)</th>
                        <th>Disapprove</th>
                      </tr>
                    </thead>
                    {users.map((user: User, index: number) => (
                      <tr key={index}>
                        <td>
                          <b>{index + 1}.</b>
                        </td>
                        <td>
                          {user?.firstName} {user?.lastName}
                        </td>
                        <td>{user?.email}</td>
                        <td>{getDate(user.createdAt)}</td>
                        <td>{user?.accountNumber}</td>
                        <td>
                          <p>
                            {[
                              user.approvedBy?.bySuperAdmin &&
                                user.approvedBy.bySuperAdmin.firstName +
                                  ' ' +
                                  user.approvedBy.bySuperAdmin.lastName,
                              user.approvedBy?.byUserAdmin &&
                                user.approvedBy.byUserAdmin.firstName +
                                  ' ' +
                                  user.approvedBy.byUserAdmin.lastName,
                            ]
                              .filter(Boolean)
                              .join(', ') || ''}
                          </p>
                          <p>
                            {[
                              user.approvedBy?.bySuperAdmin?.email,
                              user.approvedBy?.byUserAdmin?.email,
                            ]
                              .filter(Boolean)
                              .join(', ') || ''}
                          </p>
                        </td>
                        <td>
                          {user.kycInfo.optionalNote ? (
                            <HasToolTipLabel
                              labelText={
                                user.kycInfo.optionalNote.slice(0, 8) + ' ... '
                              }
                              htmlFor={`tooltip-${user.accountNumber}`}
                              id={`tooltip-${user.accountNumber}`}
                              txt={user.kycInfo.optionalNote}
                            />
                          ) : (
                            ''
                          )}
                        </td>
                        <td>
                          <QButton
                            onClick={() => {
                              setId(user?._id)
                              toggle()
                            }}
                            round
                            size="sm"
                          >
                            Disapprove
                          </QButton>
                        </td>
                      </tr>
                    ))}
                  </table>

                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    className="modal-dialog-centered"
                  >
                    {/* <ModalHeader toggle={toggle}>Disapprove User</ModalHeader> */}
                    <ModalBody>
                      {saveMessage && (
                        <div className="save-message">{saveMessage}</div>
                      )}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="disApprovalReason">
                            Reason for disapproval (Max 300 characters)
                          </label>
                          <textarea
                            id="disApprovalReason"
                            className="form-control"
                            onChange={(e) =>
                              setDisApprovalReason(e.target.value)
                            }
                            maxLength={300}
                          />
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <QButton color="primary" round onClick={toggle}>
                        Close
                      </QButton>{' '}
                      <QButton
                        loading={isDisApproveLoading}
                        round
                        color="secondary"
                        onClick={() => {
                          handleDisApproveUser()
                        }}
                      >
                        Disapprove
                      </QButton>{' '}
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
