import { Button, ButtonProps, Spinner } from "reactstrap";
import { ReactNode } from "react";
import clsx from "clsx";
interface QButtonProps extends ButtonProps {
    children: ReactNode;
    round?: boolean;
    fullWidth?: boolean;
}
export default function QButton({
    loading,
    round = false,
    fullWidth = false,
    children,
    ...props
}: QButtonProps) {
    return (
        <Button
            color="primary"
            disabled={loading}
            {...props}
            className={clsx(props.className, { "btn-round": round }, { "btn-block": fullWidth })}
        >
            <span> {children}</span>
            {loading && (
                <Spinner size="sm" className="ml-2">
                    Loading...
                </Spinner>
            )}
        </Button>
    );
}
