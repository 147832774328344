/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ONE_USD_TO_CENTS, OrderStatus } from '../../utils/types'
import { formatUnixTimestamp } from '../../utils/utilities'
import {
  getUsersForPayouts,
  postApproveBuyStripeOrder,
  postApprovePayout,
  postDisApproveBuyOrder,
} from '../../api'
import { PayoutInfo, UserInfo } from '../../utils/types'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
} from 'reactstrap'
import { TableTopFilters } from '../../component/misc/smallComponents'
import { toast } from 'react-toastify'
import SinglePayoutComponent from './SinglePayoutComponent'
const PayoutsPendingPurchase = () => {
  const [state, setState] = useState<any>({
    sortDirection: 'desc',
    isExportDownloadLoading: false,
    dataList: [],
    filteredData: [],
    searchTerm: '',
    currentPage: 1,
    pageSize: 50,
    searchType: 'name',
    selectedCurrency: '',
    paymentMethod: '',
    fromDate: '',
    toDate: '',
    sortBy: '',
  })
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const [orderId, setOrderId] = useState<any>('')
  const [loadingInitialData, setLoadingInitialData] = useState<any>(false)

  const getData = async () => {
    setLoadingInitialData(true)
    const { currentPage, pageSize, searchType, searchTerm, fromDate, toDate } =
      state
    getUsersForPayouts(
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
    )
      .then((res) => {
        setState((prevState: any) => ({
          ...prevState,
          dataList: res.data,
          filteredData: res.data,
        }))
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data.message)
      })
      .finally(() => {
        setLoadingInitialData(false)
      })
  }

  const handleApprove = () => {
    const data = {
      orderId: orderId,
    }

    postApproveBuyStripeOrder(data)
      .then((res: any) => {
        getData()
        toast.success(res.data.message)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data.message)
      })
  }
  const handleDisApprove = () => {
    const data = {
      orderId: orderId,
    }

    postDisApproveBuyOrder(data)
      .then((res: any) => {
        getData()
        toast.success(res.data.message)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }
  const handleApprovePayout = async (payout: PayoutInfo) => {
    const isValid = payout.userInfo.every(
      (user) =>
        user.orderStatus === OrderStatus.Completed ||
        user.orderStatus === OrderStatus.Approved ||
        user.orderStatus === OrderStatus.Cancelled,
    )

    // If any of the orders is not of the above status, show an error
    if (!isValid) {
      alert(
        "Cannot approve payout. Some orders are not in the 'PaymentSent' or 'Approved' status.",
      )
      return
    }
    const data = {
      payoutId: payout.payoutId,
    }

    postApprovePayout(data)
      .then((res: any) => {
        getData()
        alert('payout approved')
      })
      .catch((err) => {
        console.log(err)
        const errorMessage = err.response.data.message
        alert()
        toast.error(errorMessage)
      })
  }

  useEffect(() => {
    getData()
  }, [state.currentPage, state.fromDate, state.toDate])

  useEffect(() => {
    filterAndSortOrders()
  }, [
    // state.searchTerm,
    // state.searchType,
    state.selectedCurrency,
    state.paymentMethod,
    state.startDate,
    state.endDate,
    state.sortBy,
    state.sortDirection,
    state.dataList.length,
  ])

  const filterAndSortOrders = () => {
    let filtered = state.dataList

    // if (state.startDate) {
    //   filtered = filtered.filter(
    //     (item: any) => new Date(item.arrival_date * 1000) >= state.startDate,
    //   )
    // }

    // if (state.endDate) {
    //   filtered = filtered.filter((item: any) => {
    //     return new Date(item.arrival_date * 1000) <= state.endDate
    //   })
    // }

    setState((prevState: any) => ({ ...prevState, filteredData: filtered }))
  }

  const resetAllFiltersAndSort = () => {
    setState({
      sortDirection: 'desc',
      dataList: [],
      filteredData: [],
      searchTerm: '',
      searchType: 'name',
      selectedCurrency: '',
      orderType: '',
      paymentMethod: '',
      startDate: null,
      endDate: null,
      sortBy: '',
    })
    getData()
  }

  const handleExportOrders = async () => {
    setState((prevState: any) => ({
      ...prevState,
      isExportDownloadLoading: true,
    }))
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
      } = state
      const response = await getUsersForPayouts(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        true,
      )

      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    }
  }

  return (
    <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
      <div className="content-wrapper ">
        <div className="container-fluid">
          <ol
            className="breadcrumb"
            style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
          >
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Stripe Purchase Order</li>
          </ol>
          <TableTopFilters
            state={state}
            setState={setState}
            getData={getData}
            handleExportOrders={handleExportOrders}
          />
          {loadingInitialData && <h3 className="text-center">Loading...</h3>}

          {!loadingInitialData && state?.filteredData.length === 0 && (
            <h1 className=" text-info text-center">No Stripe Payout Found</h1>
          )}

          {!loadingInitialData && state.filteredData.length !== 0 && (
            <div className="card mb-3">
              <div className="card-body">
                {state?.filteredData &&
                  state.filteredData.map(
                    (payout: PayoutInfo, index: number) => (
                      <div key={index} className="row payout-row">
                        <div className="col-md-3">
                          <p>
                            Arrival Date:{' '}
                            <strong>
                              {formatUnixTimestamp(payout.arrival_date)}
                            </strong>
                          </p>
                        </div>
                        <div className="col-md-3">
                          <p>
                            Payout ID: <strong>{payout.payoutId}</strong>
                          </p>
                        </div>

                        <div className="col-md-2">
                          <p>
                            Quantum2 calculation:{' '}
                            <strong>
                              {Math.abs(
                                payout.totalPaymentAmountInCentsWithoutCardFees,
                              ) / ONE_USD_TO_CENTS}
                            </strong>
                          </p>
                        </div>
                        <div className="col-md-2">
                          <p>
                            Stripe Sum Paid:{' '}
                            <strong>
                              {Math.abs(payout.totalPayoutAmount) /
                                ONE_USD_TO_CENTS}{' '}
                              {payout.currency}
                            </strong>
                          </p>
                        </div>
                        <div className="col-md-2">
                          <button
                            className="btn btn-primary"
                            onClick={(e) => handleApprovePayout(payout)}
                          >
                            Approve Payout
                          </button>
                        </div>
                        <SinglePayoutComponent
                          payout={payout}
                          index={index}
                          setOrderId={setOrderId}
                          toggle={toggle}
                        />
                      </div>
                    ),
                  )}
              </div>
            </div>
          )}
          <Modal
            isOpen={modal}
            toggle={toggle}
            centered
            scrollable
            onClosed={() => {}}
          >
            <ModalHeader toggle={toggle}>
              Approve User Purchase Order
            </ModalHeader>
            <ModalBody>
              <div className="d-flex justify-content-between">
                <Button
                  color="success"
                  onClick={() => {
                    handleApprove()
                    toggle()
                  }}
                >
                  Approve
                </Button>

                <Button
                  color="primary"
                  onClick={() => {
                    handleDisApprove()
                    toggle()
                  }}
                >
                  Dis-Approve
                </Button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>{' '}
              {/* <button
                onClick={toggle}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button> */}
              {/* <button
                className="btn btn-primary"
                  handleApprove()
                  toggle()
                }}
              >
                Approve
              </button> */}
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </body>
  )
}

export default PayoutsPendingPurchase
