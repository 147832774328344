/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KYCStatus, User } from '../../utils/types'
import UpdateIcon from '@mui/icons-material/Update'
import './index.scss'
import { getDate } from '../../utils/utilities'
import { FormGroup } from 'reactstrap'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'
import {
  getPendingVerificationUsers,
  getViewOrDownloadFileFromAwsS3ForProfile,
  putApproveUser,
  putDisapproveUser,
} from '../../api'
import { HasToolTipLabel } from '../../component/misc/smallComponents'
export default function ApproveUserFromList() {
  const [users, setUsers] = useState<User[]>([])
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [documentName, setDocumentName] = useState(null)
  const [currentUserEmailViewFile, setCurrentUserEmailViewFile] = useState('')
  const [searchType, setSearchType] = useState('name') // Default search type is name
  const [searchTerm, setSearchTerm] = useState('')
  const [saveMessage, setSaveMessage] = useState('')
  const [disApprovalReason, setDisApprovalReason] = useState('')
  const [isApproveLoading, setIsApproveLoading] = useState(false)
  const [isDisApproveLoading, setIsDisApproveLoading] = useState(false)
  const [userFileUrl, setUserFileUrl] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const [doc_modal, setdoc_Modal] = useState(false)

  const toggle_doc_modal = () => setdoc_Modal(!doc_modal)
  const openModal = (documentName: any, userEmail: any) => {
    setDocumentName(documentName)
    setCurrentUserEmailViewFile(userEmail)
  }

  const getPendingApprovalUsers = async () => {
    setIsApproveLoading(true)
    getPendingVerificationUsers(searchType, searchTerm)
      .then((res) => {
        const users = res.data.users
        setUsers(users)
      })
      .catch((err) => {
        console.log('error=============', err)
      })
      .finally(() => setIsApproveLoading(false))
  }

  const handleApprove = (email: any) => {
    setIsApproveLoading(true)
    putApproveUser(email)
      .then((res) => {
        setSaveMessage(res.data.message)
        setTimeout(() => {
          setSaveMessage('')
          getPendingApprovalUsers()
          setSelectedUser(null)
          toggle()
        }, 500)
        setIsApproveLoading(false)
      })
      .catch((err) => {
        console.log('error=============', err)
        setIsApproveLoading(false)
      })
  }
  const handleDisApproveUser = async (id: string) => {
    setIsDisApproveLoading(true)
    await putDisapproveUser(id, disApprovalReason)
      .then((res) => {
        setSaveMessage(res.data.message)
        setTimeout(() => {
          setSaveMessage('')
          getPendingApprovalUsers()
          setSelectedUser(null)
          toggle()
        }, 500)
        setIsDisApproveLoading(false)
      })
      .catch((error: any) => {
        toast.error(error.response.data.message)
        setIsDisApproveLoading(false)
      })
  }
  // Debounced version of getPendingApprovalUsers for 500 mili seconds
  // const debouncedGetAllVerifiedUsers = debounce(getPendingApprovalUsers, 500)

  useEffect(() => {
    getPendingApprovalUsers()
  }, [])

  useEffect(() => {
    setErrorMessage('')
    if (!selectedUser || !documentName) return
    if (documentName) setUserFileUrl('')
    // its for viewing file directly
    getViewOrDownloadFileFromAwsS3ForProfile(
      currentUserEmailViewFile,
      documentName,
      false,
    )
      .then(({ data: { url } }) => {
        setUserFileUrl(url)
      })
      .catch((err) => {
        console.log('error', err)
        setErrorMessage(err.response.data.error)
      })
  }, [selectedUser, documentName])
  useEffect(() => {
    if (selectedUser) {
      setModal(true)
    }
  }, [selectedUser])

  const handleDownload = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault() // Prevent the default behavior of following the link
    setErrorMessage('')
    if (!selectedUser || !documentName) return
    getViewOrDownloadFileFromAwsS3ForProfile(
      currentUserEmailViewFile,
      documentName,
      true,
    )
      .then(({ data: { url } }) => {
        // Create a temporary anchor element
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.click()
      })
      .catch((err) => {
        console.log('error', err)
        setErrorMessage(err.response.data.error)
        toast.error(err.response.data.error, { position: 'bottom-center' })
      })
  }

  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Approve User from List</li>
            </ol>
            {isApproveLoading && <h4>loading....</h4>}
            {!isApproveLoading && users?.length === 0 && (
              <h4>No Users Found with Status as {KYCStatus.Pending}</h4>
            )}
            <div className="card mb-3">
              <div className="card-body">
                <div className="table-responsive">
                  <FormGroup className="search-by-wrap">
                    <select
                      className="form-control"
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                    >
                      <option value="name">Search User by Name</option>
                      <option value="email">Search User by Email</option>
                    </select>
                    {/* <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    /> */}
                    <SearchInput
                      onClick={getPendingApprovalUsers}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      // loading={userLoading}
                    />
                  </FormGroup>
                  <table
                    className="table table-bordered text-center"
                    id="dataTable"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Date Joined</th>
                        <th>Account Number</th>
                        <th>Note(Optional)</th>
                        <th>Check and Approve</th>
                      </tr>
                    </thead>
                    {users?.map((user: User, index: number) => (
                      <tr key={index}>
                        <td>
                          <b>{index + 1}.</b>
                        </td>
                        <td>{user.firstName + ' ' + user.lastName}</td>
                        <td>{user?.email}</td>
                        <td>{getDate(user.createdAt)}</td>
                        <td>{user?.accountNumber}</td>
                        <td>
                          {user.kycInfo.optionalNote ? (
                            <HasToolTipLabel
                              labelText={
                                user.kycInfo.optionalNote.slice(0, 8) + ' ... '
                              }
                              htmlFor={`tooltip-${user.accountNumber}`}
                              id={`tooltip-${user.accountNumber}`}
                              txt={user.kycInfo.optionalNote}
                            />
                          ) : (
                            ''
                          )}
                        </td>
                        <td onClick={() => setSelectedUser(user)}>
                          <UpdateIcon style={{ padding: '0px' }} />
                        </td>
                      </tr>
                    ))}
                  </table>
                  <Modal
                    className="approveUserFromList-modal"
                    isOpen={modal}
                    toggle={toggle}
                    centered
                    size="lg"
                    scrollable
                    onClosed={() => {
                      setSelectedUser(null)
                      setDocumentName(null)
                    }}
                  >
                    <ModalHeader toggle={toggle}>
                      Approving User Details
                    </ModalHeader>
                    <ModalBody>
                      {saveMessage && (
                        <div className="save-message">{saveMessage}</div>
                      )}

                      {selectedUser && (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="box_general padding_bottom add-land-form">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Full Name</label>
                                    <b className="form-control">
                                      {selectedUser.firstName +
                                        ' ' +
                                        selectedUser.lastName}
                                    </b>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Email </label>
                                    <b className="form-control">
                                      {selectedUser.email}
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Account Number</label>
                                    <b className="form-control">
                                      {selectedUser.accountNumber}
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Country Code</label>
                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.contactInfo
                                          ?.telephoneCountryCode
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Contact Number</label>

                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.contactInfo
                                          ?.phoneNumber
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Optional Document</label>
                                    <p>
                                      <button
                                        className="form-control"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          toggle_doc_modal()
                                          openModal(
                                            'kycInfo_optionalDocument',
                                            selectedUser.email,
                                          )
                                        }}
                                      >
                                        View Optional Document
                                      </button>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>House Number</label>

                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.address
                                          ?.houseNumber
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Street Address</label>
                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.address
                                          ?.streetAddress
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Additional Address</label>

                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.address
                                          ?.additionalAddress
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Town/City</label>
                                    <b className="form-control">
                                      {selectedUser.kycInfo?.address?.townCity}
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Country</label>
                                    <b className="form-control">
                                      {selectedUser.kycInfo?.address?.country}
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Postal Code</label>

                                    <b className="form-control">
                                      {selectedUser.kycInfo?.address?.postcode}
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Passport Number</label>

                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.passport
                                          ?.passportNumber
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Issuing Country</label>
                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.passport
                                          ?.issuingCountry
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Passport Start Date</label>
                                    <b className="form-control">
                                      {selectedUser.kycInfo?.passport?.startDate
                                        ? getDate(
                                            selectedUser.kycInfo.passport.startDate.toString(),
                                          )
                                        : ''}
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Passport Expiry Date</label>

                                    <b className="form-control">
                                      {selectedUser.kycInfo?.passport
                                        ?.expiryDate
                                        ? getDate(
                                            selectedUser.kycInfo.passport.expiryDate.toString(),
                                          )
                                        : ''}
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Passport Document</label>
                                    <p>
                                      <button
                                        className="form-control"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          toggle_doc_modal()
                                          openModal(
                                            'kycInfo_passportFile',
                                            selectedUser.email,
                                          )
                                        }}
                                      >
                                        View Passport Document
                                      </button>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Bank Account Name</label>

                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.bankDetails
                                          ?.bankAccountName
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Bank Name</label>
                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.bankDetails
                                          ?.bankName
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>IBAN</label>
                                    <b className="form-control">
                                      {selectedUser.kycInfo?.bankDetails?.iban}
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Bank Account Number</label>
                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.bankDetails
                                          ?.bankAccountNumber
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Bank Address</label>
                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.bankDetails
                                          ?.bankAddress
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Bank Country</label>
                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.bankDetails
                                          ?.bankCountry
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>SWIFT/BIC</label>
                                    <b className="form-control">
                                      {
                                        selectedUser.kycInfo?.bankDetails
                                          ?.swiftBic
                                      }
                                    </b>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <h4>Business Profile</h4>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Business Name</label>

                                    <b className="form-control">
                                      {selectedUser.businessInfo.businessName}
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Registration Number</label>
                                    <b className="form-control">
                                      {
                                        selectedUser.businessInfo
                                          .registrationNumber
                                      }
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Vat Number</label>

                                    <b className="form-control">
                                      {selectedUser.businessInfo.vatNumber}
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Business Address</label>
                                    <b className="form-control">
                                      {
                                        selectedUser.businessInfo
                                          .businessAddress
                                      }
                                    </b>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Registration Document</label>
                                    <p>
                                      <button
                                        className="form-control"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          toggle_doc_modal()
                                          openModal(
                                            'business_registrationDocument',
                                            selectedUser.email,
                                          )
                                        }}
                                      >
                                        View Registration Document
                                      </button>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Optional Document</label>
                                    <p>
                                      <button
                                        className="form-control"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          toggle_doc_modal()
                                          openModal(
                                            'business_optionalDocument',
                                            selectedUser.email,
                                          )
                                        }}
                                      >
                                        View Optional Document
                                      </button>
                                    </p>
                                  </div>
                                </div>
                                {/* <div>
                                  <div className="">
                                    <button
                                      className="btn_1 rounded  color btnCenter"
                                      onClick={() => {
                                        handleApprove(selectedUser.email)
                                      }}
                                      disabled={isApproveLoading}
                                    >
                                      Approve{' '}
                                      {isApproveLoading && (
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                      )}
                                    </button>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <QButton
                        color="primary"
                        round
                        onClick={() => {
                          toggle()
                          setTimeout(() => {
                            setSelectedUser(null)
                            setDocumentName(null)
                          }, 200)
                        }}
                      >
                        Close
                      </QButton>
                      <QButton
                        loading={isApproveLoading}
                        color="secondary"
                        round
                        onClick={() => {
                          handleApprove(selectedUser?.email)
                        }}
                      >
                        Approve
                      </QButton>{' '}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="disApprovalReason">
                            Reason for disapproval (Max 300 characters)
                          </label>
                          <textarea
                            id="disApprovalReason"
                            className="form-control"
                            onChange={(e) =>
                              setDisApprovalReason(e.target.value)
                            }
                            maxLength={300}
                          />
                        </div>
                      </div>
                      <QButton
                        loading={isDisApproveLoading}
                        color="secondary"
                        round
                        onClick={() => {
                          handleDisApproveUser(selectedUser?._id as string)
                        }}
                      >
                        Disapprove
                      </QButton>{' '}
                    </ModalFooter>
                  </Modal>

                  <Modal
                    className="modal-lg"
                    isOpen={doc_modal}
                    toggle={toggle_doc_modal}
                    centered
                  >
                    <ModalHeader toggle={toggle_doc_modal}>
                      <span
                        style={{
                          cursor: 'pointer',
                          color: '#004dda',
                        }}
                        onClick={handleDownload}
                      >
                        Download File
                      </span>
                    </ModalHeader>
                    <ModalBody>
                      {errorMessage && <h4>{errorMessage}</h4>}

                      <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                          className="embed-responsive"
                          src={userFileUrl}
                        />
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <QButton color="primary" onClick={toggle_doc_modal}>
                        Close
                      </QButton>{' '}
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
