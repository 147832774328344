import React from "react";
import { Input } from "reactstrap";
import QButton from "../QButton/QButton";

export default function SearchInput({
  value,
  loading = false,
  placeholder = "Search",
  onChange,
  onClick,
  ButtonText = "Search",
}: {
  value: string;
  placeholder?: string;
  ButtonText?: string;
  onClick: () => void;
  loading?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div className="input-group">
      <Input
        className="h-auto"
        value={value}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
      />
      <div className="input-group-append">
        <QButton
          loading={loading}
          color="primary"
          type="button"
          onClick={onClick}
          // disabled={value === ""}
        >
          {ButtonText}
        </QButton>
      </div>
    </div>
  );
}
