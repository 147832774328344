import React, { useState, ChangeEvent, useEffect } from 'react'
import '../../App.css'
import './index.scss'
import { Link } from 'react-router-dom'
import { ProjectDetails } from '../../utils/types'
import UpdateIcon from '@mui/icons-material/Update'
import { ONE_AUD_TO_CENTS, debounce } from '../../utils/utilities'
import { getProjectByLandNameForEdit, putProjectDetails } from '../../api'
import { FormGroup } from 'reactstrap'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'

const EditLandDetails: React.FC = () => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const [projectDetails, setProjectDetails] = useState<ProjectDetails[]>([])
  const [selectedProject, setSelectedProject] = useState<ProjectDetails | null>(
    null,
  )
  const [updatedProject, setUpdatedProject] = useState<ProjectDetails | null>(
    null,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [searchprojectByName, setSearchProjectByName] = useState<string>('')

  const handleUpdateProjectDetails = async () => {
    if (!updatedProject) return
    const isProjectDataChanged =
      JSON.stringify(selectedProject) !== JSON.stringify(updatedProject)
    if (!isProjectDataChanged) return alert('data hasnot been changed yet')

    const { landEnteredBy, startDate, ...projectDataSendData } = updatedProject
    setIsLoading(true)
    setErrorMessage('')
    putProjectDetails(projectDataSendData)
      .then(async ({ data: { message, projectDetail } }) => {
        setSaveMessage(message)
        handleLandSelect(projectDetail)
        setTimeout(() => {
          setSaveMessage('')
          setIsLoading(false)
        }, 500)
        await getProjectByLandName()
      })
      .catch((err) => {
        setIsLoading(false)
        setErrorMessage(err.response.data.message)
        toast.error(err.response.data.message, { position: 'bottom-center' })

        console.log('error', err)
      })
  }
  const getProjectByLandName = async () => {
    getProjectByLandNameForEdit(searchprojectByName)
      .then((res) => {
        setProjectDetails(res.data)
      })
      .catch((err) => {
        console.log('apis error====', err)
      })
  }

  const handleLandSelect = (project: ProjectDetails) => {
    setSelectedProject(project)
    setUpdatedProject(project)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target
    const parsedValue =
      name === 'projectValueInCents' ? parseFloat(value) * 100 : value

    setUpdatedProject((prevProject) => ({
      ...prevProject!,
      [name]: parsedValue,
    }))
  }

  const handleProjectAddressInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target
    const [outerField, innerField] = name.split('.')
    setUpdatedProject((prevProject) => ({
      ...prevProject!,
      projectAddress: {
        ...prevProject!.projectAddress,
        [innerField]: value,
      },
    }))
  }

  // Debounced version of getProjectByLandName for 500 mili seconds
  const debouncedGetProjectByLandName = debounce(getProjectByLandName, 500)

  useEffect(() => {
    // debouncedGetProjectByLandName();
  }, [searchprojectByName])
  useEffect(() => {
    getProjectByLandName()
  }, [])
  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                <Link to="/">Dashboard</Link>
              </li>
              <li
                className="breadcrumb-item active"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                Edit Land details
              </li>
            </ol>

            <div className="card mb-3">
              <div className="card-body">
                <div className="table-responsive">
                  {/* <input
										type="text"
										id="serachProjectName"
										placeholder="Enter project name"
										value={searchprojectByName}
										onChange={(e) => setSearchProjectByName(e.target.value)}
									/> */}

                  <FormGroup className="search-by-wrap">
                    {/* <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    /> */}
                    <label
                      className="mr-sm-2 mb-sm-2"
                      htmlFor="serachProjectName"
                    >
                      Search by Project Name
                    </label>
                    <SearchInput
                      onClick={getProjectByLandName}
                      value={searchprojectByName}
                      onChange={(e) => setSearchProjectByName(e.target.value)}
                      ButtonText="Search"
                      placeholder={'Search'}
                      // loading={userLoading}
                    />
                  </FormGroup>
                  <table
                    className="table table-bordered text-center"
                    id="dataTable"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Value in AUD</th>
                        <th>Cadaster</th>
                        <th>Country</th>
                        <th>Entered by</th>
                        <th>Update Land Details</th>
                      </tr>
                    </thead>
                    {projectDetails.map((projectDetail, index: number) =>
                      projectDetail.projectId ? (
                        <tr key={index}>
                          <td>
                            <b>{index + 1}.</b>
                          </td>
                          <td>{projectDetail.projectId}</td>
                          <td>{projectDetail.projectName}</td>
                          <td>
                            {projectDetail.projectValueInCents /
                              ONE_AUD_TO_CENTS}
                          </td>
                          <td>{projectDetail.projectCadaster}</td>
                          <td>{projectDetail.projectAddress.country}</td>
                          <td>
                            <p>
                              {projectDetail.landEnteredBy?.bySuperAdmin &&
                                projectDetail.landEnteredBy.bySuperAdmin
                                  .firstName +
                                  ' ' +
                                  projectDetail.landEnteredBy.bySuperAdmin
                                    .lastName}
                              {projectDetail.landEnteredBy?.byUserAdmin &&
                                projectDetail.landEnteredBy.byUserAdmin
                                  .firstName +
                                  ' ' +
                                  projectDetail.landEnteredBy.byUserAdmin
                                    .lastName}
                            </p>
                            <p>
                              {projectDetail.landEnteredBy?.bySuperAdmin?.email}
                              {projectDetail.landEnteredBy?.byUserAdmin?.email}
                            </p>
                          </td>
                          <td
                            onClick={() => {
                              handleLandSelect(projectDetail)
                              toggle()
                            }}
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                          >
                            <UpdateIcon style={{ padding: '0px' }} />
                          </td>
                        </tr>
                      ) : null,
                    )}
                  </table>
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    centered
                    scrollable
                    onClosed={() => {
                      setSelectedProject(null)
                      setUpdatedProject(null)
                      setErrorMessage('')
                    }}
                  >
                    <ModalHeader toggle={toggle}>
                      {' '}
                      Update Land Details
                    </ModalHeader>
                    <ModalBody>
                      {saveMessage && (
                        <div className="save-message">{saveMessage}</div>
                      )}

                      {updatedProject && (
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Land Project Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="projectName"
                                value={updatedProject.projectName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Land Value In AUD</label>
                              <input
                                type="number"
                                min={1}
                                name="projectValueInCents"
                                className="form-control"
                                value={
                                  updatedProject.projectValueInCents /
                                  ONE_AUD_TO_CENTS
                                }
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Cadaster Number</label>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  name="projectCadaster"
                                  className="form-control"
                                  value={updatedProject.projectCadaster}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Description</label>
                              <textarea
                                name="projectDescription"
                                className="form-control"
                                value={updatedProject.projectDescription}
                                onChange={handleInputChange}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Street Addres</label>
                              <input
                                type="text"
                                name="projectAddress.street"
                                className="form-control"
                                value={updatedProject.projectAddress?.street}
                                onChange={handleProjectAddressInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>City or Locality</label>
                              <input
                                type="text"
                                name="projectAddress.city"
                                className="form-control"
                                value={updatedProject.projectAddress.city}
                                onChange={handleProjectAddressInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Postal Code</label>
                              <input
                                type="text"
                                name="projectAddress.postal"
                                className="form-control"
                                value={updatedProject.projectAddress.postal}
                                onChange={handleProjectAddressInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>State</label>
                              <input
                                type="text"
                                name="projectAddress.state"
                                className="form-control"
                                value={updatedProject.projectAddress.state}
                                onChange={handleProjectAddressInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Country</label>
                              <input
                                type="text"
                                name="projectAddress.country"
                                className="form-control"
                                value={updatedProject.projectAddress.country}
                                onChange={handleProjectAddressInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                        onClick={() => {
                          toggle()
                          setUpdatedProject(null)
                          setSelectedProject(null)
                          setErrorMessage('')
                        }}
                        disabled={isLoading}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        disabled={isLoading}
                        onClick={() => {
                          handleUpdateProjectDetails()
                        }}
                      >
                        Update{' '}
                        {isLoading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}

export default EditLandDetails
