import { Key, useEffect, useState } from 'react'
import { getUserOrderApprovedOrCompleted } from '../../api'
import Header from '../../component/Sidbar'
import { Link } from 'react-router-dom'
import {
  SortableTableHeader,
  TableTopFilters,
} from '../../component/misc/smallComponents'
import Footer from '../../component/Footer'
import { OrderStatus, PaymentMethodEnum, Transaction } from '../../utils/types'
import { getEnteredByName } from '../../utils/utilities'
import { toast } from 'react-toastify'

const ApproveCompleted = () => {
  const [state, setState] = useState<any>({
    sortDirection: 'desc',
    isExportDownloadLoading: false,
    dataList: [],
    filteredData: [],
    currentPage: 1,
    pageSize: 50,
    searchTerm: '',
    searchType: 'name',
    orderStatus: '',
    orderType: '',
    paymentMethod: '',
    fromDate: '',
    toDate: '',
    sortBy: '',
  })

  const getData = async () => {
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
      } = state
      const res = await getUserOrderApprovedOrCompleted(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
      )
      setState((prevState: any) => ({
        ...prevState,
        dataList: res.data,
        filteredData: res.data,
      }))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
  }, [state.currentPage, state.fromDate, state.toDate])

  useEffect(() => {
    filterAndSortOrders()
  }, [
    // state.searchTerm,
    // state.searchType,
    state.orderStatus,
    state.orderType,
    state.paymentMethod,
    state.startDate,
    state.endDate,
    state.sortBy,
    state.sortDirection,
    state.dataList.length,
  ])

  const filterAndSortOrders = () => {
    let filtered = state.dataList

    if (state.orderStatus) {
      filtered = filtered.filter(
        (order: any) => order.orderStatus === state.orderStatus,
      )
    }

    if (state.orderType) {
      filtered = filtered.filter(
        (order: any) => order.orderType === state.orderType,
      )
    }

    if (state.paymentMethod) {
      filtered = filtered.filter(
        (order: any) =>
          order.paymentDetails?.paymentMethod === state.paymentMethod,
      )
    }

    if (state.sortBy) {
      filtered = filtered.sort((a: any, b: any) => {
        let comparison = 0

        if (state.sortBy === 'units') {
          const unitsA = a.currencyUnit?.numberOfUnits || 0
          const unitsB = b.currencyUnit?.numberOfUnits || 0
          comparison = unitsB - unitsA
        } else if (state.sortBy === 'amount') {
          const amountA = a.paymentDetails?.paymentAmount || 0
          const amountB = b.paymentDetails?.paymentAmount || 0
          comparison = amountB - amountA
        }

        return state.sortDirection === 'asc' ? -comparison : comparison
      })
    }

    setState((prevState: any) => ({ ...prevState, filteredData: filtered }))
  }

  const resetAllFiltersAndSort = () => {
    setState({
      sortDirection: 'desc',
      dataList: [],
      filteredData: [],
      searchTerm: '',
      searchType: 'name',
      orderStatus: '',
      orderType: '',
      paymentMethod: '',
      startDate: null,
      endDate: null,
      sortBy: '',
    })
    getData()
  }

  const handleSortTableHead = (property: string) => {
    setState((prevState: any) => {
      const newSortDirection =
        property === prevState.sortBy
          ? prevState.sortDirection === 'asc'
            ? 'desc'
            : 'asc'
          : prevState.sortDirection
      return { ...prevState, sortBy: property, sortDirection: newSortDirection }
    })
  }
  const handleExportOrder = async () => {
    setState((prevState: any) => ({
      ...prevState,
      isExportDownloadLoading: true,
    }))
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
      } = state
      const response = await getUserOrderApprovedOrCompleted(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        true,
      )
      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    }
  }
  return (
    <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
      <Header />
      <div className="content-wrapper">
        <div className="container-fluid">
          <ol
            className="breadcrumb"
            style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
          >
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              USER PURCHASES/SALES (APPROVED/COMPLETED)
            </li>
          </ol>
          <div className="card mb-3">
            <div className="card-body">
              <TableTopFilters
                state={state}
                setState={setState}
                getData={getData}
                handleExportOrders={handleExportOrder}
              />
              <div className="table-responsive approvecompleted-table new-table-update">
                <table
                  className="table text-center table-bordered"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr className="text-align-center">
                      <th>S.No.</th>
                      <th>
                        <>Name</>
                        <br />
                        <>Email</>
                      </th>
                      <th>Created Date</th>
                      <th>Currency</th>
                      <SortableTableHeader
                        label="Number"
                        property="units"
                        sortBy={state.sortBy}
                        sortDirection={state.sortDirection}
                        handleSortTableHead={handleSortTableHead}
                      />
                      <th>
                        Type
                        <select
                          className="w-100"
                          value={state.orderType}
                          onChange={(e) =>
                            setState({ ...state, orderType: e.target.value })
                          }
                        >
                          <option value="">All</option>
                          <option value="BUY">Buy</option>
                          <option value="SELL">Sell</option>
                        </select>
                      </th>
                      <th>
                        Method
                        <select
                          className="w-100"
                          onChange={(e) =>
                            setState({
                              ...state,
                              paymentMethod: e.target.value,
                            })
                          }
                        >
                          <option value="">All</option>
                          {Object.values(PaymentMethodEnum)
                            .filter(
                              (paymentMethod) =>
                                paymentMethod !== PaymentMethodEnum.None,
                            )
                            .map((method) => (
                              <option key={method} value={method}>
                                {method}
                              </option>
                            ))}
                        </select>
                      </th>
                      <th>Approved by</th>
                      <th>Payment Reference</th>
                      <th>
                        Order Status
                        <select
                          id="transactionStatus"
                          onChange={(e) =>
                            setState({ ...state, orderStatus: e.target.value })
                          }
                        >
                          <option value="">All</option>
                          {Object.values(OrderStatus).map((status) => (
                            <option key={status} value={status}>
                              {status}
                            </option>
                          ))}
                        </select>
                      </th>
                      <SortableTableHeader
                        label="Total Amount"
                        property="amount"
                        sortBy={state.sortBy}
                        sortDirection={state.sortDirection}
                        handleSortTableHead={handleSortTableHead}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {state?.filteredData &&
                      state.filteredData.map(
                        (item: Transaction, index: number) => (
                          <tr key={index} className="text-center">
                            <td>{index + 1}</td>
                            {item && (
                              <>
                                {' '}
                                <td>
                                  {item.user?.firstName +
                                    ' ' +
                                    item.user?.lastName}
                                  <br />
                                  {item.user?.email}
                                </td>
                                <td>
                                  {new Date(
                                    item.orderCreatedDate,
                                  ).toLocaleDateString()}
                                </td>
                                <td>{item.currencyUnit?.selectedCurrency}</td>
                                <td>{item.currencyUnit?.numberOfUnits}</td>
                                <td>{item.orderType}</td>
                                <td
                                  className={
                                    item.paymentDetails?.paymentMethod !==
                                    'eAUD'
                                      ? 'text-capitalize'
                                      : ''
                                  }
                                >
                                  {item.paymentDetails?.paymentMethod}
                                </td>
                                <td>{getEnteredByName(item.approvedBy)}</td>
                                <td>{item.buy?.paymentId}</td>
                                <td>{item.orderStatus}</td>
                                <td>
                                  {item.paymentDetails?.paymentAmount / 100}{' '}
                                  {item.paymentDetails?.currency}
                                </td>{' '}
                              </>
                            )}
                          </tr>
                        ),
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </body>
  )
}

export default ApproveCompleted
