import Header from '../../component/Sidbar'
import Footer from '../../component/Footer'
import { Link } from 'react-router-dom'
import './index.scss'

import ProjectAdd from '../../component/ProjectAddEdit/ProjectAdd'



export default function AddProjects() {
  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <Header />

        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">ADD PROJECT</li>
            </ol>
          </div>
          <div className="App">
            
            <div className="col-md-6 ml-auto mr-auto pt-5 common-box-shadwo ">
            <h1 style={{ textAlign: 'center' }}>Add Project</h1>
              <ProjectAdd />
            </div>
          </div>
        </div>

        <Footer />
      </body>
    </>
  )
}
