/* eslint-disable no-lone-blocks */
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ONE_AUD_TO_CENTS, getDate } from '../../utils/utilities'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { BuyCurrencyEnum } from '../../utils/types'
import {
  getUserbuyOrderPaymentSentWithBank,
  postApproveBuyBankOrder,
  postDisApproveBuyOrder,
} from '../../api'
import {
  HasToolTipLabel,
  SortableTableHeader,
  TableTopFilters,
} from '../../component/misc/smallComponents'
import { toast } from 'react-toastify'

const Userpurchse = () => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const [state, setState] = useState<any>({
    sortDirection: 'desc',
    isExportDownloadLoading: false,
    dataList: [],
    filteredData: [],
    currentPage: 1,
    pageSize: 50,
    searchTerm: '',
    searchType: 'name',
    selectedCurrency: '',
    numberOfUnits: '',
    paymentMethod: '',
    fromDate: '',
    toDate: '',
    sortBy: '',
  })

  const [orderId, setOrderId] = useState<any>('')

  const getData = async () => {
    const { currentPage, pageSize, searchType, searchTerm, fromDate, toDate } =
      state
    getUserbuyOrderPaymentSentWithBank(
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
    )
      .then((res) => {
        console.log('res.data', res.data)
        setState((prevState: any) => ({
          ...prevState,
          filteredData: res.data,
          dataList: res.data,
        }))
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data.message)
      })
  }

  const handleApprove = () => {
    const data = {
      orderId: orderId,
    }

    postApproveBuyBankOrder(data)
      .then((res: any) => {
        getData()
        toast.success(res.data.message)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }

  const handleDisApprove = () => {
    const data = {
      orderId: orderId,
    }

    postDisApproveBuyOrder(data)
      .then((res: any) => {
        getData()
        toast.success(res.data.message)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }
  useEffect(() => {
    getData()
  }, [state.currentPage, state.fromDate, state.toDate])
  useEffect(() => {
    filterAndSortOrders()
  }, [
    // state.searchTerm,
    // state.searchType,
    state.selectedCurrency,
    state.numberOfUnits,
    state.paymentMethod,
    state.startDate,
    state.endDate,
    state.sortBy,
    state.sortDirection,
    state.dataList.length,
  ])

  const filterAndSortOrders = () => {
    let filtered = state.dataList

    if (state.selectedCurrency) {
      filtered = filtered.filter(
        (order: any) =>
          order.currencyUnit.selectedCurrency === state.selectedCurrency,
      )
    }
    if (state.sortBy) {
      filtered = filtered.sort((a: any, b: any) => {
        let comparison = 0

        if (state.sortBy === 'units') {
          const unitsA = a.currencyUnit?.numberOfUnits || 0
          const unitsB = b.currencyUnit?.numberOfUnits || 0
          comparison = unitsB - unitsA
        } else if (state.sortBy === 'amount') {
          const amountA = a.paymentDetails?.paymentAmount || 0
          const amountB = b.paymentDetails?.paymentAmount || 0
          comparison = amountB - amountA
        }

        return state.sortDirection === 'asc' ? -comparison : comparison
      })
    }

    setState((prevState: any) => ({ ...prevState, filteredData: filtered }))
  }
  const handleExportOrders = async () => {
    setState((prevState: any) => ({
      ...prevState,
      isExportDownloadLoading: true,
    }))
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
      } = state
      const response = await getUserbuyOrderPaymentSentWithBank(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        true,
      )

      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    }
  }

  const resetAllFiltersAndSort = () => {
    setState({
      sortDirection: 'desc',
      dataList: [],
      filteredData: [],
      searchTerm: '',
      searchType: 'name',
      selectedCurrency: '',
      numberOfUnits: '',
      paymentMethod: '',
      startDate: null,
      endDate: null,
      sortBy: '',
    })
    getData()
  }

  const handleSortTableHead = (property: string) => {
    setState((prevState: any) => {
      const newSortDirection =
        property === prevState.sortBy
          ? prevState.sortDirection === 'asc'
            ? 'desc'
            : 'asc'
          : prevState.sortDirection
      return { ...prevState, sortBy: property, sortDirection: newSortDirection }
    })
  }
  return (
    <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
      <div className="content-wrapper ">
        <div className="container-fluid">
          <ol
            className="breadcrumb"
            style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
          >
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Bank Purchase Order</li>
          </ol>

          <div className="card mb-3">
            <div className="card-body">
              <TableTopFilters
                state={state}
                setState={setState}
                getData={getData}
                handleExportOrders={handleExportOrders}
              />
              <div className="table-responsive">
                <table
                  className="table table-bordered text-nowrap text-center new-table-update"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0 "
                >
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>User Name </th>
                      <th>Email</th>
                      <th>
                        <span className="d-block">Currency</span>

                        <select
                          className="w-100"
                          value={state.selectedCurrency}
                          onChange={(e) =>
                            setState({
                              ...state,
                              selectedCurrency: e.target.value,
                            })
                          }
                        >
                          <option value="">All</option>
                          <option value={BuyCurrencyEnum.LAND}>
                            {BuyCurrencyEnum.LAND}
                          </option>
                          <option value={BuyCurrencyEnum.eAUD}>
                            {BuyCurrencyEnum.eAUD}
                          </option>
                        </select>
                      </th>

                      <SortableTableHeader
                        label="No. of Units"
                        property="units"
                        sortBy={state.sortBy}
                        sortDirection={state.sortDirection}
                        handleSortTableHead={handleSortTableHead}
                      />
                      {/* <th>
                        <span className="d-block">Payment Method</span>

                        <select
                          className="w-100"
                          value={state.paymentMethod}
                          onChange={(e) =>
                            setState({
                              ...state,
                              paymentMethod: e.target.value,
                            })
                          }
                        >
                          <option value="">All</option>
                          <option value={PaymentMethodEnum.Card}>Card</option>
                          <option value={PaymentMethodEnum.Crypto}>
                            Crypto
                          </option>
                          <option value={PaymentMethodEnum.Bank}>Bank</option>
                          <option value={PaymentMethodEnum.eAUD}>eAUD</option>
                        </select>
                      </th> */}
                      <th>Refrence Number</th>
                      <SortableTableHeader
                        label="Total Amount in AUD"
                        property="amount"
                        sortBy={state.sortBy}
                        sortDirection={state.sortDirection}
                        handleSortTableHead={handleSortTableHead}
                      />
                      <th>Created Date</th>
                      <th>Note(Optional)</th>
                      <th>Approve/Dis-Approve</th>
                    </tr>
                  </thead>
                  {state?.filteredData &&
                    state.filteredData.map((item: any, index: number) => {
                      return (
                        <tr key={index} className="text-center">
                          <td>
                            <b>{index + 1}.</b>
                          </td>

                          <td>
                            {item.user?.firstName + ' ' + item?.user?.lastName}
                          </td>
                          <td>{item.user?.email}</td>
                          <td>{item.currencyUnit?.selectedCurrency}</td>
                          <td>{item.currencyUnit?.numberOfUnits}</td>
                          {/* <td>{item.paymentDetails?.paymentMethod}</td> */}
                          <td>{item.buy?.paymentId}</td>
                          <td>
                            {item.paymentDetails?.paymentAmount /
                              ONE_AUD_TO_CENTS}
                          </td>
                          <td>
                            {getDate(item.orderCreatedDate?.toLocaleString())}
                          </td>
                          <td>
                            {item.buy?.comments ? (
                              <HasToolTipLabel
                                labelText={
                                  item.buy?.comments?.slice(0, 8) + ' ... '
                                }
                                htmlFor={`tooltip-${item.orderId}`}
                                id={`tooltip-${item.orderId}`}
                                txt={item.buy?.comments}
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          <td
                            className="text-secondary"
                            onClick={() => setOrderId(item.orderId)}
                          >
                            <QButton size="sm" onClick={toggle}>
                              Action
                            </QButton>
                          </td>
                        </tr>
                      )
                    })}
                </table>
                <Modal
                  isOpen={modal}
                  toggle={toggle}
                  className="modal-dialog-centered"
                >
                  <ModalHeader toggle={toggle}>
                    Approve/Dis-Approve User Bank Purchase Order
                  </ModalHeader>
                  <ModalBody>
                    <div className="d-flex justify-content-between">
                      <Button
                        color="success"
                        onClick={() => {
                          handleApprove()
                          toggle()
                        }}
                      >
                        Approve
                      </Button>

                      <Button
                        color="primary"
                        onClick={() => {
                          handleDisApprove()
                          toggle()
                        }}
                      >
                        Dis-Approve
                      </Button>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  )
}

export default Userpurchse
