import { Link } from 'react-router-dom'
import './index.scss'

import { SingleQuestionInterface } from '../../utils/types'
import VoteTopicAdd from '../../component/VoteTopicAndEdit/VoteTopicAdd'

export const voteQuestionSingle: SingleQuestionInterface = {
  questionTitle: ' asdfsadf adsfasdf',
  answerKey: 'A',
  answerOptions: {
    A: 'A asdfasdf',
    B: 'B asdasdfas',
    C: 'C asdfasdfas',
    D: 'D asdfasdfasd',
  },
}

export default function AddQuestion() {
  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">ADD QUESTION/TOPIC</li>
            </ol>
          </div>
          <div className="App">
            <div className="col-md-6 ml-auto mr-auto pt-5 common-box-shadwo ">
              <h1 style={{ textAlign: 'center' }}>Add Questions</h1>
              <VoteTopicAdd />
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
