import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  BuyCurrencyEnum,
  OrderType,
  PaymentMethodEnum,
  Transaction,
} from '../../utils/types'
import {
  ONE_AUD_TO_CENTS,
  formatDate,
  getEnteredByName,
  sortByTransactionsDateAndValue,
} from '../../utils/utilities'
import { OrderStatus } from '../../utils/types'
import './index.scss'
import { getUsersTransactions } from '../../api'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { FormGroup, Label } from 'reactstrap'
import SingleDatePickerComponent from '../../component/SingleDatePickerComponent'
import moment from 'moment'
import { toast } from 'react-toastify'
import { HasToolTipLabel } from '../../component/misc/smallComponents'
export default function AdminViewTransaction() {
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchType, setSearchType] = useState('name') // Default search type is name

  const [filteredTransactions, setFilteredTransactions] = useState<
    Transaction[]
  >([])

  const [sortBy, setSortBy] = useState<string>('lastUpdatedAt')
  const [filterPaymentMethod, setFilterPaymentMethod] = useState<string | null>(
    null,
  )
  const [filterByStatus, setFilterByStatus] = useState<string | null>(null)
  const [filterCurrencyType, setFilterCurrencyType] = useState<string | null>(
    null,
  )
  const [filterTransactionType, setFilterTransactionType] =
    useState<OrderType | null>(null)
  const [fromDate, setFromDate] = useState<string>('') // Initialize with an empty string
  const [toDate, setToDate] = useState<string>('') // Initialize with an empty string
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')

  useEffect(() => {
    getAllTransation()
  }, [currentPage, fromDate, toDate])

  useEffect(() => {
    // Apply filters based on payment method,currency type,transaction type and transaction status
    let filteredTransactions = transactions

    if (!filteredTransactions) {
      return
    }
    if (filterPaymentMethod) {
      filteredTransactions = filteredTransactions.filter(
        (transaction) =>
          transaction.paymentDetails?.paymentMethod === filterPaymentMethod,
      )
    }

    if (filterByStatus) {
      filteredTransactions = filteredTransactions.filter((transaction) => {
        if (filterByStatus === OrderStatus.Completed) {
          return (
            transaction.orderStatus === OrderStatus.Completed ||
            transaction.orderStatus ===
              OrderStatus.AUDReadyToSendBetweenAccounts
          )
        } else {
          return transaction.orderStatus === filterByStatus
        }
      })
    }
    if (filterCurrencyType) {
      filteredTransactions = filteredTransactions.filter(
        (transaction) =>
          transaction.currencyUnit.selectedCurrency === filterCurrencyType,
      )
    }

    if (filterTransactionType) {
      filteredTransactions = filteredTransactions.filter(
        (transaction) => transaction.orderType === filterTransactionType,
      )
    }
    // if (fromDate && toDate) {
    //   // Filter transactions within the selected date range
    //   filteredTransactions = filteredTransactions.filter((transaction) => {
    //     const transactionDate = new Date(transaction.lastUpdatedAt)
    //     const fromDateObj = new Date(fromDate)
    //     const toDateObj = new Date(toDate)
    //     return transactionDate >= fromDateObj && transactionDate <= toDateObj
    //   })
    // }
    filteredTransactions = sortByTransactionsDateAndValue(
      sortBy,
      sortDirection,
      filteredTransactions,
    )
    setFilteredTransactions(filteredTransactions)
  }, [
    transactions,
    filterPaymentMethod,
    filterByStatus,
    filterTransactionType,
    filterCurrencyType,
  ])

  const getAllTransation = async () => {
    await getUsersTransactions(
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
    )
      .then((response) => {
        setTransactions(response.data)
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        })
        console.error('Error fetching combined data:', error)
      })
  }

  const handleSort = (
    property: string,
    curSortDirection: 'asc' | 'desc' = sortDirection,
  ) => {
    if (!filteredTransactions) {
      return
    }
    let sorted = [...filteredTransactions]

    sorted = sortByTransactionsDateAndValue(property, curSortDirection, sorted)
    setFilteredTransactions(sorted)
    setSortBy(property)

    setSortDirection(curSortDirection)
  }

  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                View Transaction History
              </li>
            </ol>
            <div className="card mb-3">
              <div className="card-header">
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <div className="d-sm-flex align-items-end justify-content-between">
                        <div>
                          <Label for="exampleSelect">Date Range:</Label>
                          <div className="d-flex">
                            <SingleDatePickerComponent
                              setDate={(d) =>
                                setFromDate(d.format('YYYY-MM-DD'))
                              }
                              date={fromDate ? moment(fromDate) : null}
                              id={'fromDate'}
                              placeholder="Start Date"
                              isBefore={false}
                            />
                            <SingleDatePickerComponent
                              setDate={(d) => setToDate(d.format('YYYY-MM-DD'))}
                              date={toDate ? moment(toDate) : null}
                              id={'toDate'}
                              isBefore={false}
                              placeholder="End Date"
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-start pl-sm-3 pr-sm-3 mb-sm-1 mt-2">
                          <button
                            className="btn btn-primary pl-3 pr-3"
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                          >
                            Prev
                          </button>
                          <p className="current-page-number ml-2 mr-2 mb-0">
                            <strong>Page {currentPage}</strong>
                          </p>
                          <button
                            className="btn btn-primary pl-3 pr-3"
                            disabled={
                              filteredTransactions
                                ? filteredTransactions.length === 0
                                : true
                            }
                            onClick={() => setCurrentPage(currentPage + 1)}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-8 col-lg-6"></div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="table-responsive">
                  <FormGroup className="search-by-wrap">
                    <select
                      className="form-control"
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                    >
                      <option value="name">Search User by Name</option>
                      <option value="email">Search User by Email</option>
                    </select>
                    <SearchInput
                      onClick={getAllTransation}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </FormGroup>
                  <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellSpacing={'0'}
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>
                          <>Sent Email</>
                          <br />
                          <>Received Email</>
                        </th>
                        <th>
                          <>Quantum2 Order Id</>
                          <br />
                          <>Stripe Payment Id/Bank Refrence</>
                        </th>
                        <th>
                          <p>Type</p>
                          <select
                            name="transactionType"
                            id="transactionType"
                            onChange={(e) =>
                              setFilterTransactionType(
                                e.target.value as OrderType,
                              )
                            }
                          >
                            <option value="">ALL</option>
                            {Object.values(OrderType).map((txType) => (
                              <option key={txType} value={txType}>
                                {txType}
                              </option>
                            ))}
                          </select>
                        </th>
                        <th>
                          <p>Currency</p>
                          <select
                            id="currencyUnitType"
                            onChange={(e) =>
                              setFilterCurrencyType(e.target.value)
                            }
                          >
                            <option value="">All</option>
                            {Object.values(BuyCurrencyEnum)
                              .filter(
                                (currency) =>
                                  currency !== BuyCurrencyEnum.initial,
                              )
                              .map((currency) => (
                                <option key={currency} value={currency}>
                                  {currency}
                                </option>
                              ))}
                          </select>
                        </th>
                        <th>Number</th>
                        <th>Value</th>
                        <th>Approved by</th>
                        <th>
                          <p>Method</p>
                          <select
                            id="paymentMethodType"
                            onChange={(e) =>
                              setFilterPaymentMethod(e.target.value)
                            }
                          >
                            <option value="">All</option>
                            {Object.values(PaymentMethodEnum)
                              .filter(
                                (paymentMethod) =>
                                  paymentMethod !== PaymentMethodEnum.None,
                              )
                              .map((method) => (
                                <option key={method} value={method}>
                                  {method}
                                </option>
                              ))}
                          </select>
                        </th>
                        <th>Note(Optional)</th>

                        <th>
                          <p>Status</p>
                          <select
                            id="transactionStatus"
                            onChange={(e) => setFilterByStatus(e.target.value)}
                          >
                            <option value="">All</option>
                            {Object.values(OrderStatus).map((status) => (
                              <option key={status} value={status}>
                                {status}
                              </option>
                            ))}
                          </select>
                        </th>

                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTransactions.map(
                        (transaction) =>
                          transaction && ( // Check if transaction is not null
                            <tr key={transaction.orderId} className="order-row">
                              <td>
                                {transaction.user.firstName +
                                  ' ' +
                                  transaction.user.lastName}
                              </td>
                              <td>
                                <>{transaction.user.email}</>
                                <br />
                                {transaction.transfer?.recipientEmail && (
                                  <> {transaction.transfer.recipientEmail}</>
                                )}
                              </td>
                              <td>
                                <>{transaction.orderId}</>
                                <br />

                                {transaction.buy?.paymentId && (
                                  <>
                                    <p className="font-weight-bold text-danger">
                                      {transaction.buy.paymentId}
                                    </p>
                                  </>
                                )}
                              </td>
                              <td>{transaction.orderType}</td>
                              <td>
                                {transaction.currencyUnit.selectedCurrency}
                              </td>
                              <td>{transaction.currencyUnit.numberOfUnits}</td>

                              <td>
                                <i className="fa fa-euro mr-6"></i>{' '}
                                {transaction.paymentDetails.paymentAmount /
                                  ONE_AUD_TO_CENTS}
                              </td>
                              <td>
                                {getEnteredByName(transaction.approvedBy)}
                              </td>
                              <td>
                                {transaction.paymentDetails?.paymentMethod}
                              </td>
                              <td>
                                {transaction.buy?.comments ? (
                                  <HasToolTipLabel
                                    labelText={
                                      transaction.buy?.comments?.slice(0, 8) +
                                      ' ... '
                                    }
                                    htmlFor={`tooltip-${transaction.orderId}`}
                                    id={`tooltip-${transaction.orderId}`}
                                    txt={transaction.buy?.comments}
                                  />
                                ) : (
                                  ''
                                )}
                              </td>
                              <td>{transaction.orderStatus}</td>
                              <td>
                                {transaction &&
                                  formatDate(
                                    transaction.lastUpdatedAt?.toString(),
                                  )}
                              </td>
                            </tr>
                          ),
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
