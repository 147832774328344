import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  getUserListByNameOrEmailForEnableDisable,
  putEnableOrDisabledUser,
} from '../../api'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { FormGroup } from 'reactstrap'
import { User } from '../../utils/types'
import { getDate } from '../../utils/utilities'

export default function DisableEnableUser() {
  const [data, setData] = useState('')
  const [id, setId] = useState<string>()
  const [searchType, setSearchType] = useState('name') // Default search type is name
  const [searchTerm, setSearchTerm] = useState('')

  const getUsers = () => {
    getUserListByNameOrEmailForEnableDisable(searchType, searchTerm)
      .then((res) => {
        setData(res.data.users)
      })
      .catch((err) => {
        console.log('error=============', err)
        toast.error(err.response.data.message)
      })
  }
  useEffect(() => {
    getUsers()
  }, [])

  const handleEnabledOrDisableUsers = (id: any) => {
    putEnableOrDisabledUser(id)
      .then((res) => {
        toast.success(res.data.message)
        getUsers()
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        console.log('error=============', err)
      })
  }
  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">ENABLE/DISABLE USER</li>
            </ol>

            <div className="card mb-3">
              <div className="card-body">
                <div className="table-responsive">
                  <FormGroup className="search-by-wrap">
                    <select
                      className="form-control"
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                    >
                      <option value="name">Search user by Name</option>
                      <option value="email">Search user by Email</option>
                    </select>

                    <SearchInput
                      onClick={getUsers}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      ButtonText="Search"
                      placeholder={'Search'}
                      // loading={userLoading}
                    />
                  </FormGroup>
                  <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Date Joined</th>
                        <th>Phone Number</th>

                        <th>Enable/Disable Users</th>
                      </tr>
                    </thead>
                    {Array.isArray(data) &&
                      data.map((item: User, index: number) => {
                        return (
                          <tr>
                            <td>
                              <b>{index + 1}.</b>
                            </td>
                            <td>{item?.firstName + ' ' + item?.lastName}</td>
                            <td>{item?.email}</td>
                            <td>{getDate(item.createdAt)}</td>
                            <td>
                              {item?.kycInfo.contactInfo.telephoneCountryCode +
                                item?.kycInfo.contactInfo.phoneNumber}
                            </td>
                            <td onClick={() => setId(item._id)}>
                              {item?.isVerified === true && item.isEnabled ? (
                                <button
                                  className="btn btn-danger bg-danger btn-sm "
                                  onClick={() => {
                                    handleEnabledOrDisableUsers(item._id)
                                  }}
                                >
                                  Disable {item.firstName + ' ' + item.lastName}{' '}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-success "
                                  style={{ backgroundColor: '#157347' }}
                                  onClick={() => {
                                    handleEnabledOrDisableUsers(item._id)
                                  }}
                                >
                                  Enable {item.firstName + ' ' + item.lastName}
                                </button>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                  </table>
                  <div
                    className="modal fade"
                    id="exampleModalCenter"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                      style={{ marginLeft: '20rem' }}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalLongTitle"
                          >
                            Enable Admin
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body"></div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              // handleEnable()
                            }}
                            data-dismiss="modal"
                          >
                            Enable
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
