import React, { useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { FormGroup, Input, Label } from 'reactstrap'
import { toast } from 'react-toastify'
import SingleDatePickerComponent from '../SingleDatePickerComponent'
import QButton from '../DesignSystem/Core/Common/QButton/QButton'
import { ProjectColorSatus, ProjectObjectInteface } from '../../utils/types'
import { validateProjectData } from '../../utils/utilities'
import { projectStatusObject, testAxios } from '../../utils/constants'
import { IsProjectCompleteOptionsComponent, ProjectStatusOptionList } from '../misc/smallComponents'
import { FormErrorMessage } from '../FormErrorMessage'
import { ProjectFormValues, projectSchema } from './ProjectAdd'

// interface ProjectFormValues {
//   projectName: string
//   projectDescription: string
//   projectManager: string
//   projectTimeStatus: string
//   projectBudgetStatus: string
//   projectQualityStatus: string
//   startDate: string
//   endDate: string
// }


const ProjectAdd: React.FC = () => {
  const navigate = useNavigate()
  const { projectID } = useParams<{ projectID: string }>()
  const [loading, setLoading] = useState(false)
  const [data, setdata] = useState(null)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProjectFormValues>({
    resolver: yupResolver(projectSchema),
  })

  useEffect(() => {
    if (projectID) {
      getSingleVotingTopic()
    }
  }, [projectID])

  const getSingleVotingTopic = async () => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URI}/project/${projectID}`
      const response = await testAxios.get(url)
      const data: ProjectObjectInteface = response.data

      if (response.data) {
        setdata(response.data)
        setValue('projectName', data.projectName)
        setValue('projectDescription', data.projectDescription)
        setValue('startDate', data.startDate)
        setValue('endDate', data.endDate)
        setValue('projectManager', data.projectManager)
        setValue('projectTimeStatus', data.projectTimeStatus)
        setValue('projectBudgetStatus', data.projectBudgetStatus)
        setValue('projectQualityStatus', data.projectQualityStatus)
        setValue('IsProjectComplete', data.IsProjectComplete)
      } else {
        navigate('/viewProjects')
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error.response.data.message)
    }
  }

  const onSubmit: SubmitHandler<ProjectFormValues> = async (data) => {
    if (validateProjectData(data)) {
      const url = `${process.env.REACT_APP_SERVER_URI}/project/${projectID}`

      try {
        setLoading(true)

        const response = await testAxios.put(url, data)
        toast.success(response.data.message, {
          position: 'bottom-center',
        })
        setLoading(false)
        navigate('/viewProjects')
      } catch (error: any) {
        setLoading(false)
        toast.error(error.response.data.message, { position: 'bottom-center' })
      }
    } else {
      toast.error('Please enter all data', {
        position: 'bottom-center',
      })
    }
  }

  return (
    <div className="row mt-4 pb-4">
      <div className="col-md-12">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>
              <b>Select Project Date Range:</b>
            </Label>
            <div className="d-flex">
              <Controller
                name="startDate"
                control={control}
                defaultValue={moment().format('YYYY-MM-DD')}
                render={({ field }) => (
                  <SingleDatePickerComponent
                    isBefore={false}
                    setDate={(d) => field.onChange(d.format('YYYY-MM-DD'))}
                    date={field.value ? moment(field.value) : null}
                    id={'startDate'}
                    placeholder="Start date"
                  />
                )}
              />
              {errors.startDate && (
                <FormErrorMessage>{errors.startDate.message}</FormErrorMessage>
              )}
              <Controller
                name="endDate"
                control={control}
                defaultValue={moment().format('YYYY-MM-DD')}
                render={({ field }) => (
                  <SingleDatePickerComponent
                    isBefore={false}
                    setDate={(d) => field.onChange(d.format('YYYY-MM-DD'))}
                    date={field.value ? moment(field.value) : null}
                    id={'endDate'}
                    placeholder="End date"
                  />
                )}
              />
              {errors.endDate && (
                <FormErrorMessage>{errors.endDate.message}</FormErrorMessage>
              )}
            </div>
          </FormGroup>
          <FormGroup>
            <Label>
              <b>Enter Project Time Status</b>
            </Label>
            <Controller
              name="projectTimeStatus"
              control={control}
              defaultValue={projectStatusObject.green.key}
              render={({ field }) => (
                <Input
                  name="projectTimeStatus"
                  type="select"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <ProjectStatusOptionList />
                </Input>
              )}
            />
            {errors.projectTimeStatus && (
              <FormErrorMessage>
                {errors.projectTimeStatus.message}
              </FormErrorMessage>
            )}
          </FormGroup>

          <FormGroup>
            <Label>
              <b>Project Completion status </b>
            </Label>
            <Controller
              name="IsProjectComplete"
              control={control}
              defaultValue={'false'}
              render={({ field }) => (
                <Input
                  name="IsProjectComplete"
                  type="select"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <IsProjectCompleteOptionsComponent />
                </Input>
              )}
            />
            {errors.projectTimeStatus && (
              <FormErrorMessage>
                {errors.projectTimeStatus.message}
              </FormErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              <b>Enter Project Budget Status</b>
            </Label>
            <Controller
              name="projectBudgetStatus"
              control={control}
              defaultValue={projectStatusObject.green.key}
              render={({ field }) => (
                <Input
                  name="projectBudgetStatus"
                  type="select"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <ProjectStatusOptionList />
                </Input>
              )}
            />
            {errors.projectBudgetStatus && (
              <FormErrorMessage>
                {errors.projectBudgetStatus.message}
              </FormErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              <b>Enter Project Quality Status</b>
            </Label>
            <Controller
              name="projectQualityStatus"
              control={control}
              defaultValue={projectStatusObject.green.key}
              render={({ field }) => (
                <Input
                  name="projectQualityStatus"
                  type="select"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <ProjectStatusOptionList />
                </Input>
              )}
            />
            {errors.projectQualityStatus && (
              <FormErrorMessage>
                {errors.projectQualityStatus.message}
              </FormErrorMessage>
            )}
          </FormGroup>
          <div className="form-group">
            <Label>
              <b>Enter Project Name</b>
            </Label>
            <Controller
              name="projectName"
              control={control}
              defaultValue="Bridg Building"
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Project Name"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.projectName && (
              <FormErrorMessage>{errors.projectName.message}</FormErrorMessage>
            )}
          </div>
          <div className="form-group">
            <Label>
              <b>Enter Project Manager</b>
            </Label>
            <Controller
              name="projectManager"
              control={control}
              defaultValue="Astrid"
              render={({ field }) => (
                <Input
                  className="form-control"
                  type="text"
                  style={{ marginBottom: '1rem' }}
                  placeholder="Enter Project Manager"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.projectManager && (
              <FormErrorMessage>
                {errors.projectManager.message}
              </FormErrorMessage>
            )}
          </div>
          <div className="form-group">
            <Label>
              <b>Enter Project Description</b>
            </Label>
            <Controller
              name="projectDescription"
              control={control}
              defaultValue="Description...."
              render={({ field }) => (
                <textarea
                  className="form-control"
                  style={{ marginBottom: '1rem' }}
                  placeholder="Enter Project Description"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.projectDescription && (
              <FormErrorMessage>
                {errors.projectDescription.message}
              </FormErrorMessage>
            )}
          </div>
          <div className="text-right ml-auto" style={{ maxWidth: '250px' }}>
            <QButton round loading={loading} type="submit" fullWidth>
              <b>Submit</b>
            </QButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProjectAdd
