import React, { useState, useEffect } from 'react'
import {
  getReferenceCodebyCode,
  postReferencecode,
  putReferencecode,
} from '../../api'
import { FormGroup } from 'reactstrap'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { Link } from 'react-router-dom'
import UpdateIcon from '@mui/icons-material/Update'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { ReferenceCode } from '../../utils/types'
import { getReferenceCodeSchema } from '../../utils/utilities'
import { FormErrorMessage } from '../../component'

const ReferenceCodeManagement: React.FC = () => {
  const [modal, setModal] = useState(false)
  const [modalCreateCode, setModalCreateCode] = useState(false)

  const toggle = () => setModal(!modal)
  const toggleCreateCode = () => setModalCreateCode(!modalCreateCode)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [saveMessage, setSaveMessage] = useState('')
  const [referenceCodes, setReferenceCodes] = useState<ReferenceCode[]>([])
  const [searchReferenceCode, setSearchReferenceCode] = useState<string>('')
  const [isLoadingCreatingCode, setIsLoadingCreatingCode] = useState(false)
  const [isLoadingUpdatingCode, setIsLoadingUpdatingCode] = useState(false)

  const [selectedReferenceCode, setSelectedReferenceCode] =
    useState<ReferenceCode | null>(null)
  const [updatedReferenceCode, setUpdatedReferenceCode] =
    useState<ReferenceCode | null>(null)

  const referenceCodeSchema = getReferenceCodeSchema()
  const defaultValues: ReferenceCode = {
    code: '',
    isPercent: false,
    isActive: false,
    amount: 0,
  }
  const {
    register: registerCreateCodeForm,
    handleSubmit: handleSubmitCreateCodeForm,
    formState: { errors: errorsCreateCodeForm },
    watch: watchCreateCodeForm,
    reset: resetCreateForm,
    trigger: triggerCreateForm,
  } = useForm<ReferenceCode>({
    defaultValues: defaultValues,
    resolver: yupResolver(referenceCodeSchema),
  })

  const {
    register: registerUpdateForm,
    handleSubmit: handleSubmitUpdateForm,
    reset: resetUpdateForm,
    formState: { errors: errorsUpdateForm },
  } = useForm<ReferenceCode>({
    resolver: yupResolver(referenceCodeSchema),
  })

  useEffect(() => {
    if (selectedReferenceCode) {
      resetUpdateForm({
        code: selectedReferenceCode.code,
        isPercent: selectedReferenceCode.isPercent,
        amount: selectedReferenceCode.isPercent
          ? selectedReferenceCode.amount
          : selectedReferenceCode.amount / 100,
        isActive: selectedReferenceCode.isActive,
      })
    }
  }, [selectedReferenceCode, resetUpdateForm])

  const getReferenceCodes = async () => {
    getReferenceCodebyCode(searchReferenceCode)
      .then((res) => {
        setReferenceCodes(res.data)
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        console.log('apis error====', err)
      })
  }

  const handleCreateCode = async (referenceCode: ReferenceCode) => {
    if (!referenceCode) return
    setIsLoadingCreatingCode(true)
    setErrorMessage('')
    if (!referenceCode.isPercent) {
      referenceCode.amount *= 100
    }

    console.log('referenceCode', referenceCode)

    postReferencecode(referenceCode)
      .then(async ({ data: { message } }) => {
        setSaveMessage(message)
        toast.success(message)
        getReferenceCodes()
        setTimeout(() => {
          setSaveMessage('')
          setIsLoadingCreatingCode(false)
        }, 500)
      })
      .catch((err) => {
        setIsLoadingCreatingCode(false)
        setErrorMessage(err.response.data.message)
        toast.error(err.response.data.message)

        console.log('error', err)
      })
  }

  const handleUpdateCode = async (updatedReferenceCode: ReferenceCode) => {
    if (!updatedReferenceCode) return
    const isReferenceCodeDataChanged =
      JSON.stringify(selectedReferenceCode) !==
      JSON.stringify(updatedReferenceCode)
    if (!isReferenceCodeDataChanged)
      return alert('data hasnot been changed yet')

    setIsLoadingUpdatingCode(true)
    setErrorMessage('')
    if (!updatedReferenceCode.isPercent) {
      updatedReferenceCode.amount *= 100
    }
    putReferencecode(updatedReferenceCode)
      .then(async ({ data: { message, referenceCode } }) => {
        setSaveMessage(message)
        handleReferenceCodeSelect(referenceCode)
        setTimeout(() => {
          setSaveMessage('')
          setIsLoadingUpdatingCode(false)
        }, 600)
        await getReferenceCodes()
      })
      .catch((err) => {
        setIsLoadingUpdatingCode(false)
        setErrorMessage(err.response.data.message)
        toast.error(err.response.data.message)

        console.log('error', err)
      })
  }
  const handleReferenceCodeSelect = (referenceCode: ReferenceCode) => {
    setSelectedReferenceCode(referenceCode)
    setUpdatedReferenceCode(referenceCode)
  }

  useEffect(() => {
    getReferenceCodes()
  }, [])

  useEffect(() => {
    if (!modalCreateCode) {
      resetCreateForm()
    }
  }, [modalCreateCode])

  const { isPercent } = watchCreateCodeForm()
  useEffect(() => {
    triggerCreateForm('amount')
  }, [isPercent])
  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                <Link to="/">Dashboard</Link>
              </li>
              <li
                className="breadcrumb-item active"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                Create/Edit Reference Code
              </li>
            </ol>

            <div className="card mb-3">
              <div className="card-body">
                <div className="table-responsive">
                  <FormGroup className="search-by-wrap">
                    <label
                      className="mr-sm-2 mb-sm-2"
                      htmlFor="serachProjectName"
                    >
                      Search by Code
                    </label>
                    <SearchInput
                      onClick={getReferenceCodes}
                      value={searchReferenceCode}
                      onChange={(e) => setSearchReferenceCode(e.target.value)}
                      ButtonText="Search"
                      placeholder={'Search'}
                      // loading={userLoading}
                    />
                  </FormGroup>
                  <button
                    className="btn btn-danger"
                    data-toggle="modal"
                    onClick={toggleCreateCode}
                  >
                    Create New Code
                  </button>
                  <table
                    className="table table-bordered text-center"
                    id="dataTable"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Is Percent</th>
                        <th>Amount</th>
                        <th>Is Active</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {referenceCodes.map((referenceCode) => (
                        <tr key={referenceCode.code}>
                          <td>{referenceCode.code}</td>
                          <td>{referenceCode.isPercent ? 'Yes' : 'No'}</td>
                          <td>
                            {referenceCode.isPercent
                              ? referenceCode.amount
                              : referenceCode.amount / 100}
                          </td>
                          <td>
                            {referenceCode.isActive ? 'Active' : 'Inactive'}
                          </td>
                          {/* <td>
                            <button onClick={() => handleEdit(referenceCode)}>
                              Edit
                            </button>
                            
                            <UpdateIcon style={{ padding: '0px' }} />
                          </td> */}
                          <td
                            onClick={() => {
                              handleReferenceCodeSelect(referenceCode)
                              toggle()
                            }}
                            data-toggle="modal"
                          >
                            <UpdateIcon style={{ padding: '0px' }} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {modalCreateCode && (
                    <Modal
                      isOpen={modalCreateCode}
                      toggle={toggleCreateCode}
                      centered
                      scrollable
                      onClosed={() => {
                        setErrorMessage('')
                        resetCreateForm()
                        console.log('hello here')
                      }}
                    >
                      <form
                        onSubmit={handleSubmitCreateCodeForm(handleCreateCode)}
                      >
                        <ModalHeader toggle={toggleCreateCode}>
                          {' '}
                          Create Reference Code
                        </ModalHeader>
                        <ModalBody>
                          {saveMessage && (
                            <div className="save-message">{saveMessage}</div>
                          )}

                          <div className="row">
                            <div className="col-md-6">
                              <FormGroup>
                                <label>Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...registerCreateCodeForm('code')}
                                />
                                <FormErrorMessage>
                                  {errorsCreateCodeForm.code?.message}
                                </FormErrorMessage>
                              </FormGroup>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <FormGroup>
                                  <label>Is Percent</label>
                                  <input
                                    type="checkbox"
                                    className="form-control"
                                    {...registerCreateCodeForm('isPercent')}
                                  />
                                  <FormErrorMessage>
                                    {errorsCreateCodeForm.isPercent?.message}
                                  </FormErrorMessage>
                                </FormGroup>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Amount</label>
                                <FormGroup>
                                  <label>Percentage or AUD</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    {...registerCreateCodeForm('amount', {
                                      //   setValueAs: (value) => {
                                      //     return value !== ''
                                      //       ? !isPercent
                                      //         ? parseFloat(value) * 100
                                      //         : value
                                      //       : 0
                                      //   },
                                    })}
                                  />
                                  <FormErrorMessage>
                                    {errorsCreateCodeForm.amount?.message}
                                  </FormErrorMessage>
                                </FormGroup>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <FormGroup>
                                  <label>Is Active</label>
                                  <input
                                    type="checkbox"
                                    className="form-control"
                                    {...registerCreateCodeForm('isActive')}
                                  />
                                  <FormErrorMessage>
                                    {errorsCreateCodeForm.isActive?.message}
                                  </FormErrorMessage>
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => {
                              toggleCreateCode()
                            }}
                            disabled={isLoadingCreatingCode}
                          >
                            Close
                          </button>
                          <button type="submit" className="btn btn-danger">
                            Create{' '}
                            {isLoadingCreatingCode && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                          </button>
                        </ModalFooter>
                      </form>
                    </Modal>
                  )}
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    centered
                    scrollable
                    onClosed={() => {
                      setSelectedReferenceCode(null)
                      setUpdatedReferenceCode(null)
                      setErrorMessage('')
                    }}
                  >
                    <form onSubmit={handleSubmitUpdateForm(handleUpdateCode)}>
                      <ModalHeader toggle={toggle}>
                        {' '}
                        Update Reference Code
                      </ModalHeader>
                      <ModalBody>
                        {saveMessage && (
                          <div className="save-message">{saveMessage}</div>
                        )}

                        {updatedReferenceCode && (
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Code</label>
                                <p>{updatedReferenceCode.code}</p>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Is Percent</label>

                                {/* <input
                                type="checkbox"
                                name="isPercent"
                                checked={updatedReferenceCode.isPercent}
                                onChange={handleInputChange}
                              /> */}
                                <input
                                  type="checkbox"
                                  {...registerUpdateForm('isPercent')}
                                />
                                {errorsUpdateForm.isPercent && (
                                  <p>{errorsUpdateForm.isPercent.message}</p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Amount</label>
                                <div className="input-group mb-3">
                                  {/* <input
                                  type="number"
                                  name="amount"
                                  className="form-control"
                                  value={updatedReferenceCode.amount}
                                  onChange={handleInputChange}
                                  min={0}
                                  max={
                                    updatedReferenceCode.isPercent ? '100' : ''
                                  }
                                /> */}
                                  <input
                                    type="number"
                                    className="form-control"
                                    {...registerUpdateForm('amount')}
                                    min="0"
                                  />
                                  {errorsUpdateForm.amount && (
                                    <p>{errorsUpdateForm.amount.message}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Is Active</label>

                                <input
                                  type="checkbox"
                                  {...registerUpdateForm('isActive')}
                                />
                                {errorsUpdateForm.isActive && (
                                  <p>{errorsUpdateForm.isActive.message}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => {
                            toggle()
                            setUpdatedReferenceCode(null)
                            setSelectedReferenceCode(null)
                            setErrorMessage('')
                          }}
                          disabled={isLoadingUpdatingCode}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-danger">
                          Update{' '}
                          {isLoadingUpdatingCode && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </ModalFooter>
                    </form>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}

export default ReferenceCodeManagement
