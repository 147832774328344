import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getDate } from '../../utils/utilities'
import { getSubscriberListByEmail } from '../../api'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { FormGroup } from 'reactstrap'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { Subscriber } from '../../utils/types'
export default function ViewSubscribers() {
  const [subscribers, setSubscribers] = useState<Subscriber[]>([])
  const [email, setEmail] = useState('')
  const [isExportDownloadLoading, setIsExportDownloadLoading] = useState(false)
  const getSubsribers = () => {
    getSubscriberListByEmail(email)
      .then((res) => {
        setSubscribers(res.data.subscribers)
      })
      .catch((err) => {
        console.log('error=============', err)
        toast.error(err.response.data.message)
      })
  }

  const handleExportSubscribers = async () => {
    setIsExportDownloadLoading(true)
    try {
      const response = await getSubscriberListByEmail(email, true)
      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'subscribers.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setIsExportDownloadLoading(false)
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setIsExportDownloadLoading(false)
    }
  }

  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View Subscribers</li>
            </ol>

            <div className="card mb-3">
              {/* <div className="card-header">
                                <i className="fa fa-list-ul mr-2"></i> List of All Transactions
                            </div> */}
              <div className="card-body">
                <div className="table-responsive">
                  <div>
                    {/* <select className="mr-sm-1 mb-sm-2" value={searchType} onChange={(e) => setSearchType(e.target.value)}>
											<option value="name">Search User by Name</option>
											<option value="email">Search User by Email</option>
										</select>
										<input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
                    <FormGroup className="search-by-wrap">
                      <SearchInput
                        onClick={getSubsribers}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        ButtonText="Search"
                        placeholder={'Search By Email'}
                        // loading={userLoading}
                      />
                    </FormGroup>
                    <QButton
                      loading={isExportDownloadLoading}
                      onClick={handleExportSubscribers}
                      round
                      size="sm"
                    >
                      Download subscribers
                    </QButton>
                  </div>
                  <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Email</th>
                        <th>Date Joined</th>
                      </tr>
                    </thead>
                    {subscribers.map(
                      (subscriber: Subscriber, index: number) => (
                        <tr key={index}>
                          <td>
                            <b>{index + 1}.</b>
                          </td>
                          <td>{subscriber.email}</td>
                          <td>{getDate(subscriber.subscribedAt)}</td>
                        </tr>
                      ),
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
