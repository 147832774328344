import React from 'react'
import '../App.css'
import { Link } from 'react-router-dom'

const Footer: React.FC = () => {
  return (
    <>
      <footer className="sticky-footer dash-foot">
        <div className="container">
          <div className="align-items-center d-flex dash-foot-wrapper flex-column flex-sm-row justify-content-between">
            <Link to="/">
              <h2 className="mb-0 handleFontsize">
                QUANTUM<i className="two">2</i>
              </h2>
            </Link>
            <span className="mb-0 text-uppercase">
              © 2023, QUANTUM<i className="two">2</i>{' '}
            </span>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
