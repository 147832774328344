import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import '../App.css'
import quantum_logo from '../pubic/assets/images/quantum2-logo.png'
import { getUserOrSuperAdmin, postLogout } from '../api/index'
import { AdminRoles } from '../utils/types'
interface HeaderProps {
  // Define any props if needed
}

const Header: React.FC<HeaderProps> = () => {
  const navigate = useNavigate()
  const [userManagementOpen, setUserManagementOpen] = useState(false)
  // const [userGoveranceOpen, setUserGoveranceOpen] = useState(false);
  const [landManagementOpen, setLandManagementOpen] = useState(false)
  const [adminManagementOpen, setAdminManagementOpen] = useState(false)
  // const [landGoveranceOpen, setLandGoveranceOpen] = useState(false);
  const [orderManagmentOpen, setOrderManagmentOpen] = useState(false)
  const [goverance, setGoverance] = useState(false)
  const [toggle, setToggle] = useState(true)
  const [authUserOrAdmin, setAuthUserOrAdmin] = useState<string>('')
  // add state for setting
  const [settingOpen, setSettingOpen] = useState(false)
  const [newsManagementOpen, setNewsManagementOpen] = useState(false)

  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false) // Corrected type
  const [loading, setLoading] = useState(true)
  const [userRoles, setUserRoles] = useState<AdminRoles[]>([])

  useEffect(() => {
    const getSuperAdmin = () => {
      getUserOrSuperAdmin()
        .then((res) => {
          const user = res.data.user
          const isSuperAdmin = user.isSuperAdmin ? true : false
          const userRoles = user.roles ? user.roles : []
          setUserRoles(userRoles)
          setIsSuperAdmin(isSuperAdmin)
          setAuthUserOrAdmin(user)
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
          setAuthUserOrAdmin('')
        })
    }
    getSuperAdmin()
  }, [])
  const [isNavCollapsed, setIsNavCollapsed] = useState(true)

  const handleNavToggle = () => {
    setIsNavCollapsed(!isNavCollapsed)
  }
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen)
  }
  const handleLogOut = () => {
    postLogout()
      .then(() => {
        window.location.reload()
        // navigate("/");
      })
      .catch((error) => console.log(error))
  }

  type DropdownItem = {
    text: string
    icon: string
    path: string
    requiredRoles: AdminRoles[]
  }

  const renderSidebarItem = (
    text: string,
    icon: string,
    path: string,
    requiredRoles: AdminRoles[],
  ): JSX.Element => {
    if (
      isSuperAdmin ||
      requiredRoles.some((role) => userRoles.includes(role))
    ) {
      return (
        <li
          className="nav-item"
          data-toggle="tooltip"
          data-placement="right"
          title={text}
        >
          <Link className="nav-link" to={path}>
            <i className={`fa fa-fw ${icon} mr-2`}></i>
            <span className="nav-link-text">{text}</span>
          </Link>
        </li>
      )
    }
    return <></>
  }

  const renderDropdownItem = (
    text: string,
    icon: string,
    items: DropdownItem[],
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ): JSX.Element => {
    // Check if user has any of the required roles for any of the dropdown items
    const hasRequiredRole = items.some((item) =>
      item.requiredRoles.some((role) => userRoles.includes(role)),
    )
    if (isSuperAdmin || hasRequiredRole) {
      return (
        <li
          className="nav-item"
          data-toggle="tooltip"
          data-placement="right"
          title={text}
        >
          <a
            className={`nav-link nav-link-collapse collapsed ${isOpen ? 'active' : ''}`}
            onClick={() => setOpen(!isOpen)}
          >
            <i className={`fa fa-fw ${icon} mr-2`}></i>
            <span className="nav-link-text">{text}</span>
          </a>
          <ul
            className={`sidenav-second-level collapse ${isOpen ? 'show' : ''}`}
          >
            {items.map((item) =>
              isSuperAdmin ||
              item.requiredRoles.some((role) => userRoles.includes(role)) ? (
                <li key={item.path}>
                  <Link to={item.path}>
                    <i className={`fa fa-fw ${item.icon} mr-2`}></i>
                    {item.text}
                  </Link>
                </li>
              ) : null,
            )}
          </ul>
        </li>
      )
    }
    return <></>
  }

  const renderSidebar = (): JSX.Element => {
    return (
      <div
        className={`collapse navbar-collapse ${isNavCollapsed ? '' : 'show'}`}
        id="navbarResponsive"
      >
        <ul className="navbar-nav navbar-sidenav style-2" id="exampleAccordion">
          {renderDropdownItem(
            'User Management',
            'fa-user-circle-o',
            [
              {
                text: 'Approve User from List',
                icon: 'fa-pencil-square-o',
                path: '/approveUserFromList',
                requiredRoles: [AdminRoles.UserAdmin],
              },
              {
                text: 'View Approved Users',
                icon: 'fa-eye',
                path: '/viewApproved',
                requiredRoles: [AdminRoles.UserAdmin],
              },
              {
                text: 'Delete User',
                icon: 'fa-trash',
                path: '/deleteUser',
                requiredRoles: [AdminRoles.SuperAdmin],
              },
              {
                text: 'View/Edit User',
                icon: 'fa-eyedropper',
                path: '/editUserDetails',
                requiredRoles: [AdminRoles.SuperAdmin],
              },
              {
                text: 'Enable/Disable User',
                icon: 'fa-ban',
                path: '/enableUser',
                requiredRoles: [AdminRoles.UserAdmin],
              },
              {
                text: 'View Subscriber',
                icon: 'fa-eye',
                path: '/view-subscribers',
                requiredRoles: [AdminRoles.UserAdmin],
              },
            ],
            userManagementOpen,
            setUserManagementOpen,
          )}
          {renderDropdownItem(
            'Admin Management',
            'fa-cog',
            [
              {
                text: 'Assign/Remove Admin role',
                icon: 'fa-toggle-on',
                path: '/assignremoveadminrole',
                requiredRoles: [AdminRoles.SuperAdmin],
              },

              {
                text: 'View Super Admin',
                icon: 'fa-toggle-on',
                path: '/viewSuperAdmin',
                requiredRoles: [AdminRoles.SuperAdmin],
              },
            ],
            adminManagementOpen,
            setAdminManagementOpen,
          )}

          {renderDropdownItem(
            'Land Management',
            'fa-map-o',
            [
              {
                text: 'Add New Land',
                icon: 'fa-plus',
                path: '/addNewLand',
                requiredRoles: [AdminRoles.LandAdmin],
              },
              {
                text: 'View Land Details',
                icon: 'fa-eye',
                path: '/viewLand',
                requiredRoles: [AdminRoles.LandAdmin],
              },
              {
                text: 'Update Land Details',
                icon: 'fa-magic',
                path: '/editLand',
                requiredRoles: [AdminRoles.SuperAdmin],
              },
            ],
            landManagementOpen,
            setLandManagementOpen,
          )}
          {renderDropdownItem(
            'News Management',
            'fa-map-o',
            [
              {
                text: 'Add News',
                icon: 'fa-plus',
                path: '/news/create',
                requiredRoles: [AdminRoles.NewsAdmin],
              },
              {
                text: 'View/ Edit/ Delete News',
                icon: 'fa-eyedropper',
                path: '/news/operation',
                requiredRoles: [AdminRoles.NewsAdmin],
              },
              // {
              //   text: 'Delete News',
              //   icon: 'fa-plus',
              //   path: '/news/delete',
              //   requiredRoles: [AdminRoles.LandAdmin],
              // },
              // {
              //   text: 'View News',
              //   icon: 'fa-eye',
              //   path: '/news/view',
              //   requiredRoles: [AdminRoles.LandAdmin],
              // },
              // {
              //   text: 'Update Land Details',
              //   icon: 'fa-magic',
              //   path: '/editLand',
              //   requiredRoles: [AdminRoles.SuperAdmin],
              // },
            ],
            newsManagementOpen,
            setNewsManagementOpen,
          )}

          {renderDropdownItem(
            'Order Management',
            'fa-money',
            [
              {
                text: 'Enter/View AUD in Bank',
                icon: 'fa-university',
                path: '/viewAudInBank',
                requiredRoles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
              },
              {
                text: 'Stripe Purchase Orders',
                icon: 'fa-pencil-square-o',
                path: '/payoutuserpurchase',
                requiredRoles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
              },
              {
                text: 'Bank Purchase Orders',
                icon: 'fa-pencil-square-o',
                path: '/bankuserpurchase',
                requiredRoles: [AdminRoles.BankAdmin],
              },
              {
                text: 'Other Purchase Orders',
                icon: 'fa-pencil-square-o',
                path: '/otherpurchase',
                requiredRoles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
              },
              {
                text: 'Sell Orders',
                icon: 'fa-eye',
                path: '/usersellorder',
                requiredRoles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
              },
              {
                text: 'Approved/Completed',
                icon: 'fa-eye',
                path: '/approvecompleted',
                requiredRoles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
              },
            ],
            orderManagmentOpen,
            setOrderManagmentOpen,
          )}

          {renderDropdownItem(
            'Governance',
            'fa-globe',
            [
              {
                text: 'Add Land Project',
                icon: 'fa-pencil-square-o',
                path: '/addLandProject',
                requiredRoles: [AdminRoles.VotingAdmin],
              },
              {
                text: 'View Land Projects',
                icon: 'fa-pencil-square-o',
                path: '/viewLandProjects',
                requiredRoles: [AdminRoles.VotingAdmin],
              },
              {
                text: 'Add New Question',
                icon: 'fa-pencil-square-o',
                path: '/addQuestion',
                requiredRoles: [AdminRoles.VotingAdmin],
              },
              {
                text: 'View Questions',
                icon: 'fa-pencil-square-o',
                path: '/viewQuestion',
                requiredRoles: [AdminRoles.VotingAdmin],
              },
              {
                text: 'Add Projects',
                icon: 'fa-pencil-square-o',
                path: '/addProject',
                requiredRoles: [AdminRoles.VotingAdmin],
              },
              {
                text: 'View Projects',
                icon: 'fa-pencil-square-o',
                path: '/viewProjects',
                requiredRoles: [AdminRoles.VotingAdmin],
              },
              {
                text: 'Community Ideas',
                icon: 'fa-globe',
                path: '/community-ideas',
                requiredRoles: [AdminRoles.VotingAdmin],
              },
            ],
            goverance,
            setGoverance,
          )}
          {renderDropdownItem(
            'Settings',
            'fa-cog',
            [
              {
                text: 'Fees Management',
                icon: 'fa-university',
                path: '/setting/feemanagement',
                requiredRoles: [AdminRoles.SuperAdmin],
              },
              {
                text: 'Reference Code Management',
                icon: 'fa-sticky-note-o',
                path: '/setting/reference-code-management',
                requiredRoles: [AdminRoles.SuperAdmin],
              },
            ],
            settingOpen,
            setSettingOpen,
          )}
          {renderSidebarItem(
            'View All Transactions',
            'fa-bar-chart',
            '/admin-view-transaction',
            [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
          )}
        </ul>
      </div>
    )
  }

  return (
    <>
      <nav
        className="dash-nav navbar navbar-expand-lg navbar-dark  fixed-top align-items-center justify-content-between"
        id="mainNav"
      >
        <div className="d-flex align-items-center align-items-lg-end">
          <div className="dropdown mr-3">
            {authUserOrAdmin && (
              <>
                <button
                  className="p-0 btn dropdown-toggle quan-toggle shadow-none"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded={isDropdownOpen}
                  onClick={handleDropdownToggle}
                >
                  <i className="ti-menu"></i>
                </button>

                <ul
                  className={`dropdown-menu quan-menu ${isDropdownOpen ? 'show' : ''}`}
                  aria-labelledby="dropdownMenuButton2"
                >
                  <li>
                    <Link to="/" className="dropdown-item">
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={handleLogOut}>
                      LOG-OUT
                    </button>
                  </li>
                </ul>
              </>
            )}
          </div>
          <div className="dropdown p-0 d-block d-lg-none ms-sm-5 ms-4">
            <button
              className="p-0 btn dropdown-toggle quan-toggle"
              type="button"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="ti-user mobile-user"></i>
            </button>
            <ul
              className="dropdown-menu quan-menu"
              aria-labelledby="dropdownMenuButton2"
            >
              <li className="nav-item">
                <Link to="/login" className="nav-link ml-3">
                  LOG-IN
                </Link>
              </li>
              <li>
                {' '}
                <Link to="/register" className="nav-link ml-3">
                  REGISTER{' '}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="d-lg-none d-block dash-mobile-logo">
          <Link to={'/'}>
            <img src={quantum_logo} alt="" />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {renderSidebar()}
      </nav>
    </>
  )
}

export default Header
