import React, { useEffect, useState } from 'react'
import Header from '../../component/Sidbar'
import Footer from '../../component/Footer'
import { Link, useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import axios from 'axios'
import moment from 'moment'
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Button,
  ModalFooter,
} from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'
import { getGovernanceQuestions } from '../../api'
import { testAxios } from '../../utils/constants'
import { ProjectObjectInteface } from '../../utils/types'
import { ColorBar } from '../../component/misc/smallComponents'
export default function ViewProjects() {
  const [deleteModal, setdeleteModal] = useState(false)
  const [deleteLoading, setdeleteLoading] = useState(false)
  const toogleDeleteModal = () => {
    setdeleteModal(!deleteModal)
  }
  const toogleEditModal = () => {
    setdeleteModal(!deleteModal)
  }
  const [data, setData] = useState<ProjectObjectInteface[]>([])
  const [questionId, setQuestionId] = useState<any>('')
  const [id, setId] = useState<any>()
  const [activeID, setactiveID] = useState<string>('')
  const [showChildComponent, setShowChildComponent] = useState(false)

  const navigate = useNavigate()

  const DeleteProject = async () => {
    try {
      setdeleteLoading(true)
      const url = process.env.REACT_APP_SERVER_URI + `/project/${activeID}`
      const response = await testAxios.delete(url)
      setdeleteLoading(false)
      toogleDeleteModal()
      toast.success(response.data.message, { position: 'bottom-center' })
      getAllData()
    } catch (e) {
      console.log('e:', e)
    }
  }

  const getAllData = async () => {
    const url = process.env.REACT_APP_SERVER_URI + '/project'
    getGovernanceQuestions().then()
    const response: any = await testAxios.get(url)
    setData(response.data)
  }
  useEffect(() => {
    getAllData()
  }, [])

  return (
    <>
      {/* <div>{showChildComponent && <EditQuestions data={id} />}</div> */}
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <Header />

        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View Projects</li>
              <li>
                <QButton
                  onClick={() => navigate('/addProject')}
                  round
                  size="sm"
                  className="ml-3"
                >
                  Add Project
                </QButton>
              </li>
            </ol>
            <table
              className="table table-bordered"
              id="dataTable"
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Project Name</th>
                  <th>Project Manager</th>
                  <th>Time Status</th>
                  <th>Budget Status</th>
                  <th>Quality Status</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Completed</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((project: ProjectObjectInteface, index: number) => {
                  console.log('project:', project)
                  return (
                    <tr key={index}>
                      <td>
                        <b>{index + 1}.</b>
                      </td>
                      <td>
                        <b>{project.projectName}.</b>
                      </td>
                      <td>
                        <b>{project.projectManager}.</b>
                      </td>
                      <td>
                        <ColorBar color={project.projectTimeStatus} />{' '}
                      </td>
                      <td>
                        <ColorBar color={project.projectBudgetStatus} />
                      </td>
                      <td>
                        <ColorBar color={project.projectQualityStatus} />{' '}
                      </td>

                      <td>{moment(project.startDate).format('DD/MM/YYYY')}</td>
                      <td>{moment(project.endDate).format('DD/MM/YYYY')}</td>
                      <td>{project.IsProjectComplete ? "Yes" : "No"}</td>

                      <td>
                        <QButton
                          size="sm"
                          onClick={() => {
                            // navigate(`/editQuestion?id=${project._id}`)
                            navigate('/editProject/' + project._id)
                            // navigate(`/feemanagement`)
                          }}
                          round
                          className="mr-2"
                        >
                          Edit
                        </QButton>
                        <QButton
                          size="sm"
                          onClick={() => {
                            if (project._id) {
                              setactiveID(project._id)
                              toogleDeleteModal()
                            }
                          }}
                          round
                        >
                          Delete
                        </QButton>
                        {/* <EditIcon /> */}
                      </td>
                      {/* <td
                    
                      >
                        <DeleteOutlineIcon />
                      </td> */}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Modal
              isOpen={deleteModal}
              toggle={toogleDeleteModal}
              className="modal-dialog-centered "
              scrollable
              // size="lg"
              unmountOnClose={true}
            >
              <ModalHeader>Confirm.</ModalHeader>
              <ModalBody>Do you want to delete this Project ?</ModalBody>

              <ModalFooter>
                <QButton round className="mr-3">
                  Close
                </QButton>
                <QButton round loading={deleteLoading} onClick={DeleteProject}>
                  Delete
                </QButton>
              </ModalFooter>
            </Modal>
          </div>
        </div>

        <Footer />
      </body>
    </>
  )
}
