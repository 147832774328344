import React, { useCallback, useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { FormGroup, Input, Label } from 'reactstrap'
import { toast } from 'react-toastify'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import QButton from '../DesignSystem/Core/Common/QButton/QButton'
import {
  LandProjectObjectInteface,
  LandProjectTypes,
  ProjectObjectInteface,
} from '../../utils/types'
import { validateProjectData } from '../../utils/utilities'
import { projectStatusObject, testAxios } from '../../utils/constants'
import {
  CustomReactQuillV3,
  IsProjectCompleteOptionsComponent,
  ProjectStatusOptionList,
} from '../misc/smallComponents'
import { FormErrorMessage } from '../FormErrorMessage'
import { LandProjectFormValues, projectSchema } from './LandProjectAdd'
import { addImage } from '../../api'

const LandProjectEdit: React.FC = () => {
  const AWS_FILE_VIEW_URI = process.env.REACT_APP_AWS_FILE_VIEW_URI
  const toggle_doc_modal = () => setdoc_Modal(!doc_modal)
  const [doc_modal, setdoc_Modal] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(false)
  const [data, setdata] = useState(null)

  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<LandProjectFormValues>({
    resolver: yupResolver(projectSchema),
  })

  useEffect(() => {
    if (id) {
      getSingleData()
    }
  }, [id])

  const getSingleData = async () => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URI}/land-project/${id}`
      const response = await testAxios.get(url)
      const data: LandProjectObjectInteface = response.data

      if (response.data) {
        setdata(response.data)
        setValue('projectName', data.projectName)
        setValue('type', data.type)
        setValue('location', data.location)
        setValue('projectDetails', data.projectDetails)
        setValue('keyDetailsAndStatistics', data.keyDetailsAndStatistics)
        setValue('projectSummary', data.projectSummary)
        setValue('landProjectPrimaryImage', data.landProjectPrimaryImage)
      } else {
        navigate('/viewLandProjects')
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error.response.data.message)
    }
  }

  const onSubmit: SubmitHandler<LandProjectFormValues> = async (data) => {
    if (validateProjectData(data)) {
      const url = `${process.env.REACT_APP_SERVER_URI}/land-project/${id}`

      try {
        setLoading(true)

        const response = await testAxios.put(url, data)
        toast.success(response.data.message, {
          position: 'bottom-center',
        })
        setLoading(false)
        navigate('/viewLandProjects')
      } catch (error: any) {
        setLoading(false)
        toast.error(error.response.data.message, { position: 'bottom-center' })
      }
    } else {
      toast.error('Please enter all data', {
        position: 'bottom-center',
      })
    }
  }

  const handleImageUpload = useCallback(() => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files?.[0]
      if (file) {
        const formData = new FormData()
        formData.append('image', file)

        try {
          const response = await addImage(formData)
          //@ts-ignore
          const imageUrl = response.data.fileUrl
          setValue('landProjectPrimaryImage', imageUrl)
          trigger('landProjectPrimaryImage')
        } catch (error) {
          console.error('Error uploading image:', error)
          alert('Error uploading image')
        }
      }
    }
  }, [])

  return (
    <div className="row mt-4 pb-4">
      <div className="col-md-12">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <Controller
              name="landProjectPrimaryImage"
              control={control}
              render={({ field }) => (
                <div className="d-flex">
                  <QButton
                    color="success"
                    onClick={handleImageUpload}
                    className="mr-4"
                  >
                    Add Image
                  </QButton>
                  {field.value && (
                    <QButton
                      color="success"
                      onClick={() => {
                        toggle_doc_modal()
                        console.log('getValues():', getValues())
                      }}
                    >
                      View Image
                    </QButton>
                  )}
                </div>
              )}
            />

            {errors.landProjectPrimaryImage && (
              <FormErrorMessage>
                {errors.landProjectPrimaryImage.message}
              </FormErrorMessage>
            )}
          </div>

          <div className="form-group">
            <Label>
              <b>Enter Project Name</b>
            </Label>
            <Controller
              name="projectName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Project Name"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.projectName && (
              <FormErrorMessage>{errors.projectName.message}</FormErrorMessage>
            )}
          </div>
          <div className="form-group">
            <Label>
              <b>Enter Location</b>
            </Label>
            <Controller
              name="location"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Project Location"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.location && (
              <FormErrorMessage>{errors.location.message}</FormErrorMessage>
            )}
          </div>
          <div className="form-group">
            <Label>
              <b>Type</b>
            </Label>
            <Controller
              name="type"
              control={control}
              defaultValue="Agricultural"
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="select"
                  placeholder="Enter Type"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <option value={LandProjectTypes.Agricultural}>
                    {LandProjectTypes.Agricultural}
                  </option>
                  <option value={LandProjectTypes.Real_Estate}>
                    {LandProjectTypes.Real_Estate}
                  </option>
                </Input>
              )}
            />
            {errors.type && (
              <FormErrorMessage>{errors.type.message}</FormErrorMessage>
            )}
          </div>
          <div className="form-group">
            <Label>
              <b>Project Key Details And Statistics</b>
            </Label>
            <FormGroup>
              <Controller
                name="keyDetailsAndStatistics"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CustomReactQuillV3
                    {...field}
                    className={'static-text-content-page'}
                    theme="snow"
                    value={field.value}
                    onChange={(v: string) => field.onChange(v)}
                    style={{
                      height: 200,
                      marginBottom: 60,
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.keyDetailsAndStatistics?.message}
              </FormErrorMessage>
            </FormGroup>
          </div>
          <div className="form-group">
            <Label>
              <b>Enter Land Project Summary</b>
            </Label>
            <FormGroup>
              <Controller
                name="projectSummary"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CustomReactQuillV3
                    {...field}
                    className={'static-text-content-page'}
                    theme="snow"
                    value={field.value}
                    onChange={(v: string) => field.onChange(v)}
                    style={{
                      height: 200,
                      marginBottom: 60,
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.projectSummary?.message}
              </FormErrorMessage>
            </FormGroup>
          </div>
          <div className="form-group">
            <Label>
              <b>Enter Land Project Details</b>
            </Label>
            <FormGroup>
              <Controller
                name="projectDetails"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CustomReactQuillV3
                    {...field}
                    className={'static-text-content-page'}
                    theme="snow"
                    value={field.value}
                    onChange={(v: string) => field.onChange(v)}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.projectDetails?.message}
              </FormErrorMessage>
            </FormGroup>
          </div>
          <div className="text-right ml-auto" style={{ maxWidth: '250px' }}>
            <QButton
              round
              onClick={() => navigate('/viewLandProjects')}
              className="mr-3"
            >
              <b>Go Back</b>
            </QButton>
            <QButton round loading={loading} type="submit">
              <b>Update</b>
            </QButton>
          </div>
        </form>
      </div>
      <Modal
        className="modal-lg"
        isOpen={doc_modal}
        toggle={toggle_doc_modal}
        centered
      >
        <ModalHeader toggle={toggle_doc_modal}></ModalHeader>
        <ModalBody>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive"
              src={getValues().landProjectPrimaryImage}
              // src={``}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <QButton color="primary" onClick={toggle_doc_modal}>
            Close
          </QButton>{' '}
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default LandProjectEdit
