import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './viewUsdInBank.scss'
import { toast } from 'react-toastify'
import { getStatistics, putAudDetails } from '../../api'

export default function ViewUnitsBank() {
  const [value, setValue] = useState<string>('')
  const [msg, setMsg] = useState<string>('')
  const [totalAUDInBank, setTotalAUDInBank] = useState<string>('')

  const handleSubmit = () => {
    if (parseInt(value) < 1 || !value) {
      setMsg('Please add a valid value')
      return
    }
    const data = {
      totalAudInBank: value,
    }

    putAudDetails(data)
      .then((res: any) => {
        setMsg(res.data.message)
        toast.success(res.data.message)
        getTransferUnits()
      })
      .catch((error: any) => {
        console.log('error', error)
        toast.error(error.response.data.message)
      })
  }
  const getTransferUnits = () => {
    getStatistics()
      .then((res) => {
        setTotalAUDInBank(res.data.totalAudInBank)
        toast.success(res.data.message)
      })
      .catch((error: any) => {
        console.log('error', error)
        toast.error(error.response.data.message)
      })
  }
  useEffect(() => {
    getTransferUnits()
  })
  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                Enter/Show Value of AUD in Bank
              </li>
            </ol>
          </div>
          <div className="center">
            <h4>Value of AUD in Bank: {totalAUDInBank}</h4>
            <br />
            <div className="form-group">
              <label>
                <b>Please Enter Value of AUD in Bank</b>
              </label>
              <input
                type="number"
                value={value}
                className="form-control"
                placeholder="Enter Value "
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <h5 className="mb-2" style={{ color: 'red' }}>
              {msg}
            </h5>
            <button className="btn_1 medium handleBtn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </body>
    </>
  )
}
